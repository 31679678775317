import React, {forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useGetCall } from "seed/api";
import View from "components/flow/exec_mode/ExecMode.view";

const ExecMode = forwardRef((props, ref) => {

  const { flow, onExecuteNode } = props;

  const [activeTab, setActiveTab] = useState(null);
  const [callGetExecModeSettings, reqGetExecModeSettings] = useGetCall("/flows/get_exec_mode_settings", { flow_id: flow.id });
  const execModeSettings = reqGetExecModeSettings.data ?? [];

  useImperativeHandle(ref, () => ({
		refetchExecModeSettings() {
			return callGetExecModeSettings({ });
		}
	}))

  useEffect(() => {
    callGetExecModeSettings({ });
  }, []);

  useEffect(() => {
    if(execModeSettings.length > 0 && activeTab == null)
      setActiveTab(execModeSettings.findIndex(execModeSetting => execModeSetting));
  }, [execModeSettings]);

  return <View
    flow={flow}
    execModeSettings={execModeSettings}
    activeTab={activeTab}
    setActiveTab={setActiveTab}
    onExecuteNode={onExecuteNode}
  />;

})

ExecMode.displayName = "ExecMode";

export default ExecMode;