import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "seed/helpers";
import Section from "components/presentation/templates/side_template/Section";
import Sidenav from "components/presentation/templates/side_template/Sidenav";


const SideTemplateView = ({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) => (
  <div id="side-template">
    <Sidenav flow={flow} showTabs={showTabs} onOpenEdit={onOpenEdit} onOpenTemplates={onOpenTemplates} onOpenShare={onOpenShare} />

    <main id="content" role="main" className="main ml-0">
      <div className="content container-fluid ml-0">
        <Switch>
          <Route path="/section/:sectionIdx" component={(props) => <Section {...props} flowId={flow.id} />} />
          <Redirect to="/section/0" />
        </Switch>
      </div>
    </main>

  </div>
);

export default SideTemplateView;