import React, { useState, useEffect } from "react";
import { useDetail, useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import * as parser from "components/flow/Controller.lib.parser";
import * as tutorial from "components/flow/Flow.lib.tutorial"
import View from "components/flow/Flow.view";

function Flow({ match, isAnonymous = false }) {

  const userId = sessionStorage.getItem("id");
  const [activePage, setActivePage] = useState(null);
  const flowId = match?.params?.flowId;
  const [history, setHistory] = useState([{ nodes: [], edges: [], annotations: [] }]);
  const [historyPosition, setHistoryPosition] = useState(0);

  const [callActivateApps, reqActivateApps] = usePost('/flows/update_apps');

  useEffect(() => {
    callActivateApps({
      flowId: flowId,
    })
  }, [])

  const filterPage = `flow.id=${flowId} AND ` + (activePage ? `page.id=${activePage}` : "page.name=Default");

  const reqUser = useDetail(`{
    user {
      accountSettings
    }
  }`, userId)

  const reqFlow = useDetail(`{
    flow {
      name
      token
      executions {
        status
        startDate
        isRead
      }
      user {
        email
      }
    }
  }`, flowId);

  const reqNodes = useQuery(`{
    flowNodes {
      idx
      inputValue
      outputValue
      uiSettings
      isEnabled
      action { name }
      isVerified
      isIterable
      iterableField
      flow { name }
    }
  }`, filterPage, {
    onCompleted: (data) => {
      const flowNodes = data?.flowNodes ?? [];
      const nodes = parser.parseNodesDatabaseToUi(flowNodes);
      setHistory((prevHistory) => {
        if (typeof prevHistory == "object" && prevHistory.length > 0) {
          prevHistory[0].nodes = nodes;
        }
        return prevHistory;
      });
    }
  });

  const reqEdges = useQuery(`{
    flowEdges {
      type
      sourceName
      targetName
      source { idx }
      target { idx }
      flow { name }
    }
  }`, filterPage, {
    onCompleted: (data) => {
      const flowEdges = data?.flowEdges ?? [];
      const edges = parser.parseEdgesDatabaseToUi(flowEdges);
      setHistory((prevHistory) => {
        if (typeof prevHistory == "object" && prevHistory.length > 0) {
          prevHistory[0].edges = edges;
        }
        return prevHistory;
      });
    }
  });

  const reqAnnotations = useQuery(`{
    flowAnnotations {
      idx
      inputValue
      uiSettings      
      action { name }
      flow { name }
    }
  }`, filterPage, {
    onCompleted: (data) => {
      const flowAnnotations = data?.flowAnnotations ?? [];
      const annotations = parser.parseAnnotationsDatabaseToUi(flowAnnotations);
      setHistory((prevHistory) => {
        if (typeof prevHistory == "object" && prevHistory.length > 0) {
          prevHistory[0].annotations = annotations;
        }
        return prevHistory;
      });
    }
  });

  const reqActions = useQuery(`{
    actions {
      name
      type
      inputStructure
      outputStructure
      uiSettings
      app { name }
    }
  }`)

  useEffect(() => {
    reloadFlow();
  }, [activePage])

  const reloadFlow = () => {
    setHistory([{ nodes: [], edges: [], annotations: [] }]);
    setHistoryPosition(0);
    reqFlow.refetch();
    reqNodes.refetch();
    reqEdges.refetch();
    reqAnnotations.refetch();
  }

  const onUpdateHistory = (allNodes, edges) => {
    const nodes = allNodes.filter((item) => !item.isAnnotation);
    const annotations = allNodes.filter((item) => item.isAnnotation);
    setHistory((prev) => [...prev.slice(0, historyPosition + 1), { nodes: nodes, edges: edges, annotations: annotations }]);
    setHistoryPosition((prev) => prev + 1);
  }

  if (reqFlow.loading || reqNodes.loading || reqEdges.loading ||
    reqAnnotations.loading || reqActions.loading || reqUser.loading) return <EmptyView />;
  if (reqFlow.error || reqNodes.error || reqEdges.error ||
    reqAnnotations.error || reqActions.error || reqUser.loading) return <div></div>;

  const { flow = {} } = reqFlow.data;
  const { flowNodes = [] } = reqNodes.data;
  const { flowEdges = [] } = reqEdges.data;
  const { flowAnnotations = [] } = reqAnnotations.data;
  const { actions = [] } = reqActions.data;
  const { user = {} } = reqUser.data;

  const tutorialSettings = user.accountSettings?.tutorial ?? []
  const flowTutorial = tutorialSettings.find((tutorial) => tutorial.name == "flow") || {};
  const isTutorialFinished = flowTutorial?.is_finished ?? false;
  const currentTutorialStep = flowTutorial?.current_step ?? 0;

  const nodes = parser.parseNodesDatabaseToUi(flowNodes);
  const edges = parser.parseEdgesDatabaseToUi(flowEdges);
  const annotations = parser.parseAnnotationsDatabaseToUi(flowAnnotations);
  console.log("history: ", history);
  console.log("historyPosition: ", historyPosition);

  return (
    <View
      flow={flow}
      nodes={nodes}
      edges={edges}
      annotations={annotations}
      actions={actions}
      activePage={activePage}
      tutorialSteps={tutorial.flowTutorialSteps}
      currentTutorialStep={currentTutorialStep}
      history={history}
      historyPosition={historyPosition}
      isTutorialShown={!isTutorialFinished}
      isAnonymous={isAnonymous}
      setActivePage={setActivePage}
      setHistoryPosition={setHistoryPosition}
      reloadFlow={reloadFlow}
      onUpdateHistory={onUpdateHistory}
    />
  );
}

const EmptyView = () =>
  <div className="content container-fluid px-1 px-md-3 py-3">
    <div className="d-flex justify-content-center">
      <div className="card p-0" style={{ width: "100%", height: "95vh", overflowY: "auto" }}>
        <div class="card-body" style={{ padding: "10rem 0" }}>
          <Loading />
        </div>
      </div>
    </div>
  </div>

export default Flow;