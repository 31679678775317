import React from "react";
import lodash from "lodash";
import * as Fields from "components/schema_form/fields";
import { HelpComponent } from "components/schema_form/fields/commons";
import SchemaHandler from "components/schema_form/SchemaForm.sub.SchemaHandler.view";


const InputComponent = ({
  input,
  appId,
  actionId,
  flowId,
  nodeId,
  sectionIdx,
  values,
  parentKey,
  ignoreSamplePicker,
  isDeveloperMode,
  isReadOnly,
  isCommentEnabled,
  setFieldValue,
  setValues,
}) => {

  const name = parentKey == "" ? input.name : parentKey + "." + input.name;
  const type = input.type;
  const label = input.label ?? "";
  const value = lodash.get(values, name);
  const hidden = input?.hidden ?? false;
  const required = input?.required ?? !hidden;
  const disabled = input?.disabled ?? isReadOnly;
  const caption = input.caption ?? "";
  const helpMessage = input?.help_message;
  const placeholder = input?.placeholder;
  const multiple = input?.multiple ?? false;
  const enableComments = input?.enable_comments ?? isCommentEnabled;

  const developerOnly = input?.developer_only ?? false;

  const textType = input?.text_type ?? "general";
  const rows = input?.rows ?? 5;
  const options = input?.options ?? [];
  const optionsIcon = input?.options_icon
  const dynamicOptions = input?.dynamic_options ?? {};
  const multipleFiles = input?.multiple_files ?? false;
  const acceptFiles = input?.accept_files ?? "*";
  const acceptTypes = input?.accept_types ?? "all";
  const selectorFilters = input?.selector_filters ?? [];
  const enableAttributes = input?.enable_attributes ?? true;
  const integrationNames = input?.integration_names ?? [];
  const defaultAccountName = input?.default_account_name;
  const addAccountButtonText = input?.add_account_button_text;
  const schemaButtonText = input?.schema_button_text;
  const schemaFieldsPlaceholder = input?.schema_fields_placeholder;
  const valueAAttrs = input?.value_a_attrs;
  const valueBAttrs = input?.value_b_attrs;
  const valueAPlaceholder = input?.value_a_placeholder
  const valueBPlaceholder = input?.value_b_placeholder
  const valueADefault = input?.value_a_default
  const valueBDefault = input?.value_b_default
  const conditionPlaceholder = input?.condition_placeholder
  const conditionDefault = input?.condition_default
  const showStyles = input?.show_styles ?? false;
  const showSaveAction = input?.show_save_action ?? false;
  const valueASource = input?.value_a_source
  const valueBSource = input?.value_b_source
  const valueAHeader = input?.value_a_header
  const valueBHeader = input?.value_b_header
  const valueAReference = input?.value_a_reference
  const valueBReference = input?.value_b_reference

  const openText = input?.open_text ?? false;
  const saveCollectionColumns = input?.save_collection_columns;
  const showCollectionCondition = input?.show_collection_condition ?? true;
  const showCollectionAddButton = input?.show_collection_add_button ?? false
  const displayAttributesManually = input?.display_attributes_manually ?? false;
  


  const fields = input?.fields ?? []
  const enableCollapse = input?.enable_collapse ?? false
  const collapsed = input?.collapsed ?? false

  const buttonType = input?.button_type ?? "CALL";
  const buttonAction = input?.button_action ?? "";
  const buttonText = input?.button_text ?? "";
  const buttonStyle = input?.button_style ?? "outline";
  const buttonCallback = input?.button_callback ?? (() => { });
  const labelText = input?.label_text ?? "";
  const labelFontStyle = input?.label_font_style ?? "normal"
  const labelFontSize = input?.label_font_size ?? "normal"


  const hiddenId = "section-hidden-" + name.replaceAll("[", "").replaceAll("]", "").replaceAll(".", "-")

  const commonProps = {
    name,
    label,
    type,
    value,
    required,
    hidden,
    disabled,
    multiple,
    caption,
    helpMessage,
    placeholder,
    enableComments,
    values,
    appId,
    actionId,
    flowId,
    nodeId,
    setFieldValue,
    input,
    sectionIdx,
  }

  if (developerOnly && !isDeveloperMode) return <></>;
  if (ignoreSamplePicker && type == "SAMPLE_PICKER") return <></>;

  return (
    (() => {

      switch (type) {

        case "TEXT":
          return (
            <Fields.TextField
              {...commonProps}
              textType={textType}
            />
          );

        case "TEXT_AREA":
          return (
            <Fields.TextAreaField
              {...commonProps}
              rows={rows}
            />
          );

        case "SELECT":
          return (
            dynamicOptions.source == null ?
              <Fields.SelectField
                {...commonProps}
                options={options}
                optionsIcon={optionsIcon}
                openText={openText}
              /> :
              <Fields.DynamicSelectField
                {...commonProps}
                dynamicOptions={dynamicOptions}
                optionsIcon={optionsIcon}
                openText={openText}
              />
          )

        case "DATE":
          return (
            <Fields.TextField
              {...commonProps}
              textType={"date"}
            />
          );

        case "DATE_TIME":
          return (
            <Fields.TextField
            {...commonProps}
            textType={"datetime-local"}
          />
          );

        case "FILE":
          return (
            <Fields.FileField
              {...commonProps}
              acceptFiles={acceptFiles}
              multipleFiles={multipleFiles}
            />
          );

        case "CHECKBOX":
          return (
            <Fields.CheckboxField
              {...commonProps}
            />
          );

        case "CODE":
          return (
            <Fields.CodeField
              {...commonProps}
            />
          );

        case "VARIABLE":
          return (
            <Fields.VariableSelector
              {...commonProps}
              acceptTypes={acceptTypes}
              selectorFilters={selectorFilters}
              openText={openText}
              enableAttributes={enableAttributes}
              textType={textType}
              showCollectionAddButton={showCollectionAddButton}
              showCollectionCondition={showCollectionCondition}
              saveCollectionColumns={saveCollectionColumns}
              displayAttributesManually={displayAttributesManually}
            />
          );

        case "SCHEMA_BUILDER":
          return (
            <Fields.SchemaBuilderField
              {...commonProps}
              schemaButtonText={schemaButtonText}
              schemaFieldsPlaceholder={schemaFieldsPlaceholder}
              schema={value}
            />
          );

        case "MATCH":
          return (
            <Fields.MatchField
              {...commonProps}
              showSaveAction={showSaveAction}
              valueASource={valueASource}
              valueBSource={valueBSource}
              valueAPlaceholder={valueAPlaceholder}
              valueBPlaceholder={valueBPlaceholder}
              valueAHeader={valueAHeader}
              valueBHeader={valueBHeader}
              valueAReference={valueAReference}
              valueBReference={valueBReference}
            />
          );

        case "ACCOUNT_PICKER":
          return (
            <Fields.AccountPicker
              {...commonProps}
              integrationNames={integrationNames}
              defaultAccountName={defaultAccountName}
              addAccountButtonText={addAccountButtonText}
            />
          );

        case "LABEL":
          return (
            <Fields.LabelField
              {...commonProps}
              labelText={labelText}
              labelFontStyle={labelFontStyle}
              labelFontSize={labelFontSize}
            />
          );

        case "BUTTON":
          return (
            <Fields.ButtonField
              {...commonProps}
              buttonType={buttonType}
              buttonAction={buttonAction}
              buttonText={buttonText}
              buttonStyle={buttonStyle}
              buttonCallback={buttonCallback}
            />
          );

        case "RICH_TEXT":
          return (
            <Fields.RichText
              {...commonProps}
              singleLine={true}
              showStyles={false}
            />
          )

        case "RICH_TEXT_AREA":
          return (
            <Fields.RichText
              {...commonProps}
              singleLine={false}
              rows={rows}
              showStyles={showStyles}
            />
          )

        case "FILTER":
          return (
            <Fields.FilterField
              {...commonProps}
              valueAAttrs={valueAAttrs}
              valueBAttrs={valueBAttrs}
              valueAPlaceholder={valueAPlaceholder}
              valueBPlaceholder={valueBPlaceholder}
              valueADefault={valueADefault}
              valueBDefault={valueBDefault}
              conditionPlaceholder={conditionPlaceholder}
              conditionDefault={conditionDefault}
            />
          )

        case "SECTION":
          return (
            <div className="w-100 mt-1">

              <a class="d-flex mb-1 w-100 justify-content-between" data-toggle="collapse" href={"#" + hiddenId}
                style={{ cursor: enableCollapse ? "pointer" : "default" }}>
                {label != "" ?
                  <span class="dropdown-header p-0">
                    <span style={{ color: "#777" }}>{label}</span>
                    {helpMessage != null ? <HelpComponent helpMessage={helpMessage} /> : null}
                  </span> : null}
                {enableCollapse ? <i style={{ color: "#aaa" }} class="ml-1 fas fa-chevron-down"></i> : null}
              </a>
              <hr class="mt-2 w-100" style={{ border: "none", height: "1.5px", background: "#e7eaf3" }} />
              <div className="mt-4">
                <div className={collapsed ? "collapse" : "show"} id={enableCollapse ? hiddenId : ""}>
                  <SchemaHandler schema={fields} appId={appId} actionId={actionId} flowId={flowId}
                    values={values} setFieldValue={setFieldValue} setValues={setValues}
                    ignoreSamplePicker={ignoreSamplePicker} isDeveloperMode={isDeveloperMode}
                    isReadOnly={isReadOnly} isCommentEnabled={isCommentEnabled}
                    parentKey={`${name}`} sectionIdx={sectionIdx}
                  />
                </div>
              </div>
            </div>
          );

        default:
          return <></>;
      }
    })())
}

export default InputComponent;