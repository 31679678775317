import React from "react";
import { ModalRoute } from "seed/helpers";
import { Link } from "react-router-dom";
import FlowList from "components/misc/settings/flows/FlowList";
import FormSetFlow from "components/start/flows/FormSetFlow";
import FormSaveFlow from "components/start/flows/FormSaveFlow";

const FlowsView = () => (
  <div id="flows" className="card">

    <div className="card-header">
      <h3 className="card-title">Procesos</h3>
      <Link to="/settings/flows/0/create_flow" className="btn btn-sm btn-primary">
        <i class="fas fa-plus mr-1"></i> Crear proceso
      </Link>
    </div>

    <div className="card-body">
      <FlowList />
    </div>

    <ModalRoute
      width="500"
      height="450"
      path="/settings/flows/:flowId/edit_flow"
      component={FormSetFlow}
    />

    <ModalRoute
      width="470"
      height="450"
      path="/settings/flows/:folderId/create_flow"
      onSave={() => window.location.href = "/app/settings/flows"}
      component={FormSaveFlow}
    />

  </div>
);

export default FlowsView;