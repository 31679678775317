const parseData = (values, execModeDefaults, collections) => {

  if(!values.reference) return {};

  const defaults = execModeDefaults.find(d => d.root.id.toString() == values.reference);
  if(!defaults) return {};

  const formattedCollections = collections.map(c => ({ name: `@${c.name}`, id: c.id }));
  const tables = values.tables.map(t => {
    const table = formattedCollections.find(c => c.name == t.name);
    if(!table) return {};
    return {
      id: table.id,
      name: table.name,
      columns: t.columns,
      view: t.view
    };
  });

  return {
    root: defaults.root,
    title: values.title,
    is_form: defaults.is_form,
    is_trigger: defaults.is_trigger,
    left_panel: values.left_panel,
    right_panel: values.right_panel,
    tables: tables,
    is_manual_title: values.is_manual_title,
    is_manual_reference: values.is_manual_reference,
    is_manual_left_panel: values.is_manual_left_panel,
    is_manual_right_panel: values.is_manual_right_panel,
    is_manual_tables: values.is_manual_tables,
  };

}

const getInitialValues = (settings) => {
  return {
    title: settings?.title,
    reference: settings?.root?.id?.toString(),
    left_panel: settings.left_panel,
    right_panel: settings.right_panel,
    tables: settings?.tables?.map(t => ({
      name: t.name,
      columns: t.columns??[],
      view: t.view??'TABLE'
    })),
    is_manual_title: settings?.is_manual_title??true,
    is_manual_reference: settings?.is_manual_reference??true,
    is_manual_left_panel: settings.is_manual_left_panel??true,
    is_manual_right_panel: settings.is_manual_right_panel??true,
    is_manual_tables: settings?.is_manual_tables??true
  };
}

const getPanelOptions = (values, execModeDefaults, is_right) => {

  const options = [];
  const defaults = execModeDefaults.find(d => d.root.id.toString() == values.reference);
  if(!defaults) return [];

  if(defaults.is_form)
    options.push({ label: "Formulario", value: "FORM" });

  if(defaults.tables.length > 0)
    options.push({ label: "Datos", value: "TABLE" });

  const panel = is_right ? values.left_panel : values.right_panel;

  options.push({ label: "Actividad", value: "ACTIVITY_CHART" });
  options.push({ label: "Vacío", value: "EMPTY" });

  ["FORM", "TABLE", "ACTIVITY_CHART", "EMPTY"].forEach(panelType => {
    if (panel === panelType) {
      options.splice(options.findIndex(o => o.value === panelType), 1);
    }
  });
    
  return options;

}

const getColumnOptions = (valueTable, collections) => {

  const formattedCollections = collections.map(c => ({ name: `@${c.name}`, id: c.id, columns: c.schema?.structure??[] }));
  const table = formattedCollections.find(c => c.name == valueTable);
  if(!table) return [];

  return table.columns.map(c => ({ label: c.label, value: c.name }));

}

export { parseData, getInitialValues, getPanelOptions, getColumnOptions };