import React from "react";
import SuggestionPrompt from "components/flow/suggestions/Suggestions.view.lib.prompt";
import Results from "components/flow/suggestions/Results";

const sortExamples = (examples) => {
  const priority = {
    "suggestion": 1,
    "instruction": 2,
    "question": 3
  };
  return examples.sort((a, b) => priority[a?.type ?? a?.content?.response?.type] - priority[b?.type ?? b?.content?.response?.type]);
}

const SuggestionsView = ({
  flowId,
  promptRef,
  isLoading,
  isExamplesLoading,
  text,
  active,
  result = [],
  examples = [],
  setText,
  setActive,
  onGenerateResponse,
  onGenerateExamples,
  onCloseResult,
  onPromptRemove
}) => {
  return (
    <div 
      id="suggestions" 
      className="d-flex"
      style={{ 
        position: "relative", 
        zIndex: 5,
      }}
    >

      {
        active && 
          <div 
            className="card animate__animated animate__fadeIn"
            
            style={{
              position: "absolute",
              height: "30rem",
              width: "24rem",
              bottom: "4rem",
              right: "2.5rem",
              "--animate-duration": "0.6s"
            }}
          >
            <div 
              className="card-header d-flex align-items-center justify-content-between px-2 p-0 py-1 bg-primary"
            >
              <div className="h5 m-0 py-1 px-2 text-white">
                Asistente
              </div>
              <div>
                <button
                  className="btn btn-sm text-white p-0 m-0"
                  onClick={() => setActive(false)}
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div 
              className="card-body p-0 scrollbar-narrow" 

              style={{
                overflowY: "auto",
                overflowX: "hidden"
              }}
            >

              <div className="mr-2 px-2 pb-2">
                <div className="scrollbar-narrow">
                  {
                    result?.map((r, i) =>
                      <Results
                        key={i}
                        flowId={flowId}
                        result={r}
                        onGenerateResponse={onGenerateResponse}
                        onClose={() => onCloseResult(i, r.id)}
                      />
                    )
                  }
                </div>
              </div>
              
              {
                active && !isLoading && text?.length == 0 && result?.length == 0
                  ? <div
                      className={"d-flex align-items-end h-100 animate__animated animate__fadeIn w-100 px-2 pb-3"}
                      style={{
                        right: "0px",
                        "--animate-duration": "0.6s"
                      }}
                    >
                      <div className="w-100">

                        {
                          !isExamplesLoading &&
                            <div class="d-flex justify-content-end dropdown-header p-0 m-0 pb-2 mr-1">
                            <span style={{ color: "#999" }}>Ejemplos</span>
                          </div>
                        }

                        {
                          !isExamplesLoading && sortExamples(examples).map((e, i) =>
                            <div
                              key={i}
                              
                              className="float-right"
                            >
                              <SuggestionPrompt
                                type={e?.response?.type}
                                text={e?.response?.text}
                                onClick={() => setText(e?.response?.text)}
                              />
                            </div>
                          )
                        }
                                    
                        <button
                          type="button"
                          className="btn btn-sm float-right m-0 p-0 mb-2 mr-1"
                          onClick={onGenerateExamples}
                          
                        >
                          {
                            isExamplesLoading
                              ? <><i class="fas fa-spinner fa-spin mr-2"></i>Cargando</>
                              : <><i class="fas fa-undo mr-2"></i>Más ejemplos</>
                          }
                        </button>
                      
                      </div>
                    </div>
                  : null
              }

            </div>
            <div className="card-footer p-0 m-0" >
              <div className="w-100" >
                <form  onSubmit={e => { e.preventDefault(); onGenerateResponse(text) }} >
                  <div 
                    class="d-flex justify-content-between prompt-wrapper bg-white pl-3 py-1 rounded"                     
                    style={{ 
                      position: "relative",
                    }}
                  >

                    <input
                      type="text"
                      required={true}
                      class="prompt-input border-0 pl-0 pr-5"
                      placeholder="¿En qué te puedo ayudar?"
                      
                      ref={promptRef}
                      onChange={(e) => { setText(e.target.value); setActive(true) }}
                      onFocus={() => setActive(true)}
                      onKeyDown={(e) => e.key === "Enter" && onGenerateResponse(text)}
                      onClick={() => setActive(true)}
                      value={text}
                      style={{ width: isLoading ? "calc(100% - 120px)" : "calc(100% - 97px)", fontSize: "0.97em"  }}
                    />

                    {
                      text != "" && 
                        <i 
                          className="prompt-close fas fa-times px-2 py-1"
                          
                          onClick={onPromptRemove}
                          style={{
                            position: "absolute",
                            right: isLoading ? "7.5rem" : "6rem", 
                            top: "0.3rem", 
                            fontSize: "0.9rem", 
                            color: "#a0aab0"
                          }}
                        />
                    }

                    <button
                      type="submit"
                      
                      className="btn btn-sm btn-accent font-weight-bold mr-1"
                      style={{
                        paddingLeft: "0.75rem",
                        paddingRight: "0.85rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem"
                      }}
                    >
                      {
                        isLoading
                          ? <><i class="fas fa-spinner fa-spin mr-2"></i>Cargando</>
                          : <><i class="fas fa-play mr-2"></i>Enviar</>
                      }
                    </button>

                  </div>
                </form>
              </div>
            </div>
          </div>
      }

      <button
        className="btn btn-primary"
        
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "3.5rem",
          height: "3.5rem",
          fontSize: "1.5rem",
          borderRadius: "50%",
          zIndex: 10
        }}
        onClick={() => setActive(!active)}
      >
        {
          active
            ? <i className="fas fa-chevron-down"></i>
            : <i className="fas fa-question"></i>
        }
      </button>

    </div>
  );
}

export default SuggestionsView;