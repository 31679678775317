import React from "react";
import { Link } from "react-router-dom";
import { StyleTag } from "seed/helpers";
import HelpMessage from 'components/util/helpers/HelpMessage';
import {
  returnFlowMessage,
  changeTemplateMessage,
  customizeTemplateMessage,
  sharePresentationMessage
} from "components/presentation/util/messages";


const TopnavView = ({ flow, showTabs, pathname, onOpenEdit, onOpenTemplates, onOpenShare }) => (
  <div id="topnav">
    <header id="header" class="navbar navbar-expand-xl navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered ml-0">
      <div class="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">

        <div class="navbar-brand-wrapper d-block">
          <a class="navbar-brand" aria-label="Front">
            <img class="navbar-brand-logo" src="/theme/svg/logos/logo-white.svg" alt="Logo" />
          </a>
          {showTabs && (
            <li id="return-tab" className="nav-item d-none d-sm-inline-block ml-4">
              <HelpMessage message={returnFlowMessage}>
                <button className="btn btn-sm"
                  onClick={() => window.parent.location.href = `/app/flow/${flow.id}/flow`}>
                  <i class="fas fa-arrow-left mr-1"></i> Ir a proceso
                </button>
              </HelpMessage>
            </li>
          )}
        </div>

        <div id="control-tabs" class="navbar-nav-wrap-content-right">
          <ul class="navbar-nav align-items-center flex-row">

            <li className="nav-item d-none d-sm-inline-block">
              <HelpMessage message={changeTemplateMessage}>
                <button className="btn btn-sm btn-light" onClick={onOpenTemplates}>
                  <i class="fas fa-th-large mr-2"></i> Ajustar plantilla
                </button>
              </HelpMessage>
            </li>

            <li className="nav-item d-none d-sm-inline-block mr-3">
              <HelpMessage message={customizeTemplateMessage}>
                <button className="btn btn-sm btn-light" onClick={onOpenEdit}>
                  <i class="fas fa-pencil-ruler mr-2"></i> Personalizar diseño
                </button>
              </HelpMessage>
            </li>

            <li className="nav-item d-none d-sm-inline-block">
              <HelpMessage message={sharePresentationMessage}>
                <button id="edit_presentation_button" className="btn btn-sm text-white" onClick={onOpenShare}>
                  <i class="fas fa-share mr-1"></i> Compartir
                </button>
              </HelpMessage>
            </li>

            <li class="nav-item" style={{ visibility: "hidden" }}>
              <button type="button" class="navbar-toggler btn btn-ghost-secondary rounded-circle" aria-label="Toggle navigation"
                aria-expanded="false" aria-controls="navbarNavMenu" data-toggle="collapse" data-target="#navbarNavMenu">
                <i class="tio-menu-hamburger"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>

    <div id="section-tabs" className="row justify-content-center">
      {(flow.presentationSettings?.sections ?? []).length > 1 && (
        flow.presentationSettings?.sections ?? []).map((section, idx) => (
          <Link to={`/section/${idx}`} key={section.title}>
            <div className={`col-auto nav-badge ${pathname.replaceAll("section", "").replaceAll("/", "") == idx ? "active" : ""}`}>
              <span class="badge badge-pill badge-light py-2 px-3">
                <i className="fa-solid fa-minus mr-3"></i>
                {section.title}
              </span>
            </div>
          </Link>
        )
        )}
    </div>
  </div>
);

export default TopnavView;