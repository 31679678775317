import React from "react";

const SuggestionPrompt = ({ icon, type, text, onClick }) => 
  <div 
    className="d-inline-block pl-3 pr-2 py-2 bg-white mb-2"
    style={{ borderRadius: "2rem", border: "1px solid rgb(218, 221, 224)" }}
    
    >
    <div 
      className="d-flex align-items-center" 
      style={{ cursor: "pointer" }}
      onClick={() => onClick(text)}
      
    >
      <i 
        className={function(){
          if(type == "suggestion") return "fas fa-lightbulb";
          if(type == "instruction") return "fas fa-play";
          if(type == "question") return "fas fa-question-circle";
        }()} 
        style={{ marginRight: "0.7rem", cursor: "pointer", color: "#757a80"}} 
         
      />
      <label 
        className="h6 m-0 mr-2" 
        style={{ cursor: "pointer" }}
        
        onClick={() => onClick(text)}
      >
        <i>{text}</i>
      </label>
    </div>
  </div>;

export default SuggestionPrompt;