import React from 'react';
import Executions from "components/start/executions/Executions";

const ExecModeCharts = ({ 
  flow, 
  isFullWidth,
  execModeSetting, 
  onExecuteNode 
}) => {
  return <div 
    style={{
      height: "100%",
      overflowY: "auto",
    }}
  >
    {
      !execModeSetting.is_trigger &&
        <div className="d-flex justify-content-end">
          <button 
            type="button"
            className="btn btn-sm btn-success px-3 mx-5 mt-3 mb-0" 
            onClick={() => onExecuteNode(execModeSetting.root.id)}
          >
            <i className="fas fa-play"></i> Ejecutar
          </button>
        </div>
    }
    <Executions
      flow={flow}
      showList={true}
      listPosition={isFullWidth ? "left" : "top"}
      showCharts={isFullWidth}
      styles={{
        background: "#FFF",
        height: "100%",
      }}
    />
  </div>;
}

export default ExecModeCharts;