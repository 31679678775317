import React, { useEffect, useState, useRef } from "react";
import swal from "sweetalert";
import { useGetCall, usePost } from "seed/api";
import { useQuery } from "seed/gql";
import { parseData, getInitialValues, getColumnOptions, getPanelOptions } from "components/flow/exec_mode/Settings.lib.form";
import View from "components/flow/exec_mode/Settings.view";

function Settings({ flow, execModeRef }) {

  const [activeTab, setActiveTab] = useState(0);
  const [execModeSettings, setExecModeSettings] = useState(null);
  const formRef = useRef(null);

  const qCollections = useQuery(`{
		collections {
			id
			name
			initials
      schema
		}
	}`, "flowId=" + flow.id, {
    onCompleted: (data) => {
      console.log(data);
    }
  });

  const [callDefaults, reqDefaults] = useGetCall("/flows/get_exec_mode_defaults", { flow_id: flow.id });
  const [callSettings, reqSettings] = useGetCall("/flows/get_exec_mode_settings", { flow_id: flow.id }, {
    onCompleted: (data) => setExecModeSettings(data)
  });

  const [callSet, reqSet] = usePost("/flows/set_exec_mode_settings", {
    onCompleted: (data) => {
      execModeRef.current.refetchExecModeSettings();
      callSettings();
      callDefaults();
      swal("Guardado", "Los cambios se han guardado correctamente", "success");
    }, 
    onError: (err) => {
      swal("Error al guardar", "Por favor cierre sesión y vuelva a entrar", "error")
    }
  })

  const execModeDefaults = reqDefaults.data;
  const collections = qCollections.data?.collections;

  useEffect(() => {
    callSettings();
    callDefaults();
  }, []);

  useEffect(() => {
    if(!execModeSettings || !execModeDefaults) return;
    formRef.current.setValues(getInitialValues(execModeSettings[activeTab] ?? {}));
  }, [execModeSettings]);

  const checkSections = (values) => {
    const keys = ["title", "reference", "left_panel", "right_panel"];
    if(!keys.every(key => key in values && values[key])) {
      swal("Advertencia", "Antes de cambiar de sección, complete todos los campos", "warning");
      return false;
    }
    return true;
  }

  const onAddSection = (values) => {
    if(!checkSections(values)) return;
    setExecModeSettings([...execModeSettings, {}]);
    setActiveTab(execModeSettings.length);
    formRef.current.setValues({});
  }

  const onChangeTab = (values, index) => {
    
    if(index == activeTab) return;
    if(!checkSections(values)) return;

    setActiveTab(index);
    setExecModeSettings(execModeSettings.map((settings, idx) => {
      if(idx == activeTab)
        return parseData(values, execModeDefaults, collections);
      return settings;
    }));

    formRef.current.setValues(
      getInitialValues(execModeSettings[index] ?? {})
    );

  }

  const onCreateTable = (values) => {
    const tables = (values.tables??[]).map(t => collections.find(c => `@${c.name}` == t))
    if(!tables.find(t => !t)) return qCollections.refetch();
  }

  const onChangeSelectedTable = (value, index) => {
    const table = collections.find(c => `@${c.name}` == value);
    if(!table) return;
    const columns = table.schema.structure.map(c => c.name);
    formRef.current.setFieldValue(`tables[${index}].columns`, columns);
  }

  const onDelete = (index) => {
    setExecModeSettings(execModeSettings.filter((_, idx) => idx != index));
    setActiveTab(0);
  }

  const onSubmit = (values) =>
    callSet({
      "flow_id": flow.id,
      "sections": execModeSettings.map((settings, idx) => {
        if(idx == activeTab)
          return parseData(values, execModeDefaults, collections);
        return settings;
      })
    });

  const onReset = () => {
    setExecModeSettings(execModeDefaults);
    formRef.current.setValues(getInitialValues(execModeDefaults[activeTab] ?? {}));
  }

  if(reqSettings.loading || reqDefaults.loading || !execModeDefaults || !execModeSettings)
    return null;

  return <View
    formRef={formRef}
    flow={flow}
    collections={collections}
    execModeDefaults={execModeDefaults}
    execModeSettings={execModeSettings}
    activeTab={activeTab}
    getPanelOptions={getPanelOptions}
    getColumnOptions={getColumnOptions}
    onCreateTable={onCreateTable}
    onChangeSelectedTable={onChangeSelectedTable}
    onAddSection={onAddSection}
    onChangeTab={onChangeTab}
    onDelete={onDelete}
    onSubmit={onSubmit}
    onReset={onReset}
  />;

}

export default Settings;