import React from "react";
import { useNodes, useReactFlow } from 'reactflow';
import { useHistory } from 'react-router-dom';
import View from "components/flow/board/nodes/Note.view";

function Note({ id, data }) {

  const nodes = useNodes();
  const historyRouter = useHistory();
  const { setNodes } = useReactFlow();

  const isSelected = data?.is_selected ?? false;
  const input = data?.input_value ?? [];
  let content = "";
  let readOnly = false;
  for (let inp of input) {
    if (inp.name == "content")
      content = inp.value
    if (inp.name == "read_only")
      readOnly = inp.value
  }

  if (readOnly)
    content = content.replace("{internal_id}", id)

  const onEditNote = () => {
    const note = nodes.find((nd) => nd.id == id);
    if (note) {
      const url = `/flow/edit_annotation/${note.remoteId}`
      historyRouter.replace(url);
    }
    return nodes;
  }

  const onSelectAnnotation = () => {
    let actualNode = nodes.find((nd) => nd.id == id);
    actualNode.data.is_selected = !isSelected;
    setNodes(nodes);
    data.onSelectNode()
  }

  console.log("isSelected", isSelected);

  return (
    <View
      id={id}
      content={content}
      readOnly={readOnly}
      isSelected={isSelected}
      onEditNote={onEditNote}
      onDeleteNode={data.onDeleteNode}
      onSelectAnnotation={onSelectAnnotation}
    />
  );
}

export default Note;