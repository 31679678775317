
const returnFlowMessage = "Regresa al proceso y crea mas acciones para completar tu sitio web";
const changeTemplateMessage = "Personaliza el estilo de tu sitio web, usa el diseño que mejor se adapte a tus necesidades"
const customizeTemplateMessage = "Modifica las secciones y datos provenientes de tu proceso que apareceran en tu sitio web";
const sharePresentationMessage = "Comparte el sitio web con otras personas para que puedan interactuar con tu sitio web";


export { 
    returnFlowMessage, 
    changeTemplateMessage, 
    customizeTemplateMessage, 
    sharePresentationMessage 
}