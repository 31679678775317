/* eslint-disable max-len */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Modal } from "seed/helpers";
import Tour from "components/misc/tour/Tour"
import { formatNumber } from "components/tables/util/format";
import Table from "components/tables/Table";
import FormSave from "components/tables/forms/FormSave";
import FormSet from "components/tables/forms/FormSet";
import FormImport from "components/tables/forms/FormImport";
import HelpMessage from 'components/util/helpers/HelpMessage';
import "styles/css/Collection.css";


const createTableMessage = "Crea tablas personalizadas y adaptadas para guardar información del proceso, en esta puedes definir columnas y tipos de datos";
const importExcelDataMessage = "Importa datos directamente desde un archivo Excel para integrarlos en tu proceso"


const TablesView = ({
  styles,
  flowId,
  showTabs,
  columns,
  rows,
  pageNum,
  pageSize,
  totalPages,
  collections,
  collectionColumns,
  activeCollectionId,
  query,
  filters,
  search,
  height,
  loading,
  tutorialSteps,
  currentTutorialStep,
  isTutorialShown,
  isModalOpen,
  isEditModalOpen,
  isImportModalOpen,
  isImportModalShown,
  isExportModalShown,
  isFilterModalShown,
  isOptionModalShown,
  isReadOnly,
  onClickTab,
  onClickDelete,
  setIsModalOpen,
  setIsEditModalOpen,
  setIsImportModalOpen,
  onAddColumn,
  onClickEdit,
  onChangeColumn,
  onChangeColumnOptions,
  onDeleteColumn,
  onAddRow,
  onDeleteRow,
  onInsertRow,
  onChangeRow,
  onImport,
  onExport,
  onDownloadZip,
  onChangeSearch,
  onPageChange,
  onChangeQuery,
  onChangeFilters,
  onClickCell,
  onSaveCollection,
  onEditCollection,
}) => (
  <div id="tables" class="px-2 py-1" style={styles}>

    <BrowserRouter basename="/app/data">

      <Tour
        steps={tutorialSteps}
        startAt={currentTutorialStep}
        show={isTutorialShown}
        tutorialName={"collections"}
      >
        {showTabs && (
          <div className="w-100" id="tables-header">
            <div id="collection-tabs" className="scrollbar-narrow d-flex w-100 align-items-baseline pt-3 px-3 pb-2">
              {(collections ?? []).map((collection, index) => (
                <a
                  key={collection.id}
                  className={"btn collection-tab d-flex align-items-center mr-2 px-3 py-0 " +
                    (activeCollectionId == collection.id ? "active" : "")}
                >
                  <span className="text-truncate py-2" onClick={() => onClickTab(collection.id)}>{collection.name}</span>
                  <div class="dropright collection-options">
                    {!isReadOnly && (
                      <a
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="pl-2 fas fa-ellipsis-v tab-icon"></i>
                      </a>
                    )}
                    <div class="dropdown-menu py-1 collection-options-content">
                      <div className="d-flex">
                        <a
                          href="#"
                          class="dropdown-item px-3"
                          onClick={() => onClickEdit(collection.id)}
                        >
                          <i class="tio-edit dropdown-item-icon" ></i> Editar
                        </a>
                        <a
                          class="dropdown-item px-3"
                          href="#"
                          onClick={() => onClickDelete(collection.id)}
                        >
                          <i class="tio-delete dropdown-item-icon"></i> Eliminar
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              ))}

              {!isReadOnly && (
                <div id="add-collection-tab">

                  <div class="dropdown">

                    <button
                      id="create-table-dropdown"
                      class="btn collection-tab d-flex align-items-center ml-2 dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="fas fa-plus mr-2 tab-icon"></i>
                        <span>Agregar tabla</span>
                      </span>
                    </button>

                    <div id="create-table-dropdown-content">
                      <div>
                        <div class="dropdown-menu" aria-labelledby="create-table-dropdown">

                          <HelpMessage message={createTableMessage}>
                            <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setIsModalOpen(true)}>
                              <div>
                                <i class="tio-table dropdown-item-icon"></i>
                                <span>Crear tabla</span>
                              </div>
                            </span>
                          </HelpMessage>

                          <HelpMessage message={importExcelDataMessage}>
                            <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setIsImportModalOpen(true)}>
                              <i class="tio-file-add dropdown-item-icon"></i>
                              <span>Importar desde excel</span>
                            </span>
                          </HelpMessage>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              )}

            </div>
          </div>
        )}

        <div className="h-100" id="tables-body">

          {!activeCollectionId && !loading && (
            <div className="w-100 h-100" id="no-tables-content">
              <div className="d-flex flex-column justify-content-center align-items-center w-100 h-75">
                <h3 className="text-muted">
                  {
                    collections && collections.length > 0 && !isReadOnly
                      ? "No se ha seleccionado ninguna tabla"
                      : "No hay tablas creadas"
                  }
                </h3>
                {
                  collections && collections.length == 0 && !loading && !isReadOnly &&
                  <div id="no-table-ctas" className="d-flex justify-content-center">
                    <div>
                      <HelpMessage message={createTableMessage}>
                        <button
                          type="button"
                          className="btn btn-sm btn-lighter-white mt-2"
                          onClick={() => setIsModalOpen(true)}
                        >
                          <i className="fas fa-plus mr-2"></i>
                          Crear tabla
                        </button>
                      </HelpMessage>
                    </div>
                    <div>
                      <HelpMessage message={importExcelDataMessage}>
                        <button
                          type="button"
                          className="btn btn-sm btn-lighter-white mt-2 ml-2"
                          onClick={() => setIsImportModalOpen(true)}
                        >
                          <i className="fas fa-file-import mr-2"></i>
                          Importar excel
                        </button>
                      </HelpMessage>
                    </div>
                  </div>
                }
              </div>
            </div>
          )}

          {activeCollectionId && (
            <Table
              trackKeybindings={(
                !isModalOpen && !isEditModalOpen && !isImportModalShown &&
                !isExportModalShown && !isFilterModalShown && !isOptionModalShown
              )}
              isReadOnly={isReadOnly || !showTabs}
              useCustomPagination={true}
              useCustomSearch={true}
              useCustomFilters={true}
              useCustomImport={true}
              useCustomExport={true}
              showExport={!isReadOnly}
              showImport={!isReadOnly}
              showOptions={!isReadOnly}
              showSearch={!isReadOnly}
              showFilters={!isReadOnly}
              pageNum={pageNum}
              pageSize={pageSize}
              totalPages={totalPages}
              values={rows}
              structure={columns}
              collections={collections}
              collectionColumns={collectionColumns}
              query={query}
              filters={filters}
              search={search}
              height={height}
              loading={loading}
              onAddColumn={onAddColumn}
              onChangeColumn={onChangeColumn}
              onChangeColumnOptions={onChangeColumnOptions}
              onDeleteColumn={onDeleteColumn}
              onAddRow={onAddRow}
              onDeleteRow={onDeleteRow}
              onInsertRow={onInsertRow}
              onChangeRow={onChangeRow}
              onImport={onImport}
              onExport={onExport}
              onDownloadZip={onDownloadZip}
              onPageChange={onPageChange}
              onChangeSearch={onChangeSearch}
              onChangeQuery={onChangeQuery}
              onChangeFilters={onChangeFilters}
              onClickCell={onClickCell}
              folioComponent={(data, _) => {
                const collection = collections?.find(c => c.id == activeCollectionId);
                if (!data.folio) return;
                if (!collection) return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data.folio}</label>;
                return <label className="text-center" style={{ minWidth: "2.85rem" }}>{collection.initials}-{formatNumber(data.folio)}</label>;
              }}
            />
          )}

        </div>
      </Tour>

      {
        isModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onSaveCollection}
          onClose={() => setIsModalOpen(false)}
          component={FormSave}
        />
      }

      {
        isEditModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsEditModalOpen(false)}
          component={FormSet}
        />
      }

      {
        isImportModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsImportModalOpen(false)}
          component={FormImport}
        />
      }

    </BrowserRouter>
  </div>
);

export default TablesView;