import React from "react";
import { BaseEdge, EdgeLabelRenderer } from 'reactflow';
import "styles/css/Edge.css";

const EdgeView = ({
  selected,
  edgePath,
  markerEnd,
  style,
  label,
  labelX,
  labelY,
  onDeteleEdge,
  onInsertCondition,
  onInsertIteration
}) => (
  <>
    <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
    <EdgeLabelRenderer>
      <div
        className="edge nodrag nopan"
        style={{ transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)` }}
      >
        <div class="dropdown">
          {label && (<p className="edge-label">{label}</p>)}
          <div class={`dropdown-menu py-2 ${selected && "show"}`}>

            <a class="dropdown-item" onClick={onDeteleEdge}>
              <i class="fas fa-trash mr-2"></i>Eliminar
            </a>

          </div>
        </div>

      </div>
    </EdgeLabelRenderer>
  </>
);

export default EdgeView;