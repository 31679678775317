import React from 'react';
import { OverlayTrigger } from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';
import * as lib from "components/flow/toolbox/util/toolbox";
import HelpMessage from 'components/util/helpers/HelpMessage';


const ActionElement = ({ action, app, isSuggestion = false, onClickAction, onDragStart }) => {

  const uiSettings = action.uiSettings;
  const hasSubtitle = uiSettings?.form?.subtitle != null || uiSettings?.general?.subtitle != null
  const hasCaption = uiSettings?.form?.caption != null || uiSettings?.general?.caption != null

  return (
    <div className="action-element">
      <OverlayTrigger key={action.id} placement="right" delay={{ show: 100 }}
        overlay={
          hasCaption ?
            <Popover id="toolbox-action-popover">
              <Popover.Body className="p-3"
                style={{
                  backgroundColor: "#f0f3f5", border: "1px solid #e0e5ea",
                  color: "#40454a", borderRadius: "10px", maxWidth: "12rem"
                }}>
                <div>{uiSettings?.form?.caption ?? uiSettings?.general?.caption}</div>
              </Popover.Body>
            </Popover> : <div></div>
        }>

        <a class="btn btn-outline-secondary btn-action px-3 w-100 d-inline-block"
          style={{
            marginBottom: !isSuggestion ? "0.27rem" : "0.35rem",
            paddingTop: !isSuggestion ? "0.77rem" : "0.7rem",
            paddingBottom: !isSuggestion ? "0.77rem" : "0.7rem",
            zIndex: "5"
          }}
          draggable onClick={() => onClickAction(action.id)}
          onDragStart={(event) => onDragStart(event, "node", action.id)}
        >

          <div className="d-flex align-items-center justify-content-start w-100">

            <i className={`app-icon ${app.uiSettings?.icon}`}></i>

            <div class="text-left">
              <div class="pb-0 mb-0 action-title">
                {lib.getActionName(action)}
                {action.developerOnly ? (
                  <span className="ml-1 d-block mb-1 action-note">
                    <i class="fas fa-code"></i> Developer only
                  </span>
                ) : null}
                {action.underDev ? (
                  <span className="ml-1 d-block mb-1 action-note">
                    <i class="fas fa-wrench"></i> Under development
                  </span>
                ) : null}

              </div>

              <div className='action-subtitle'>
                {hasSubtitle ? <label className="d-block">
                  {uiSettings?.form?.subtitle ?? uiSettings?.general?.subtitle}</label> : null}
              </div>

              {action.type == "TRIGGER" ?
                <div className={`action-trigger-label dropdown-header pt-1 pb-0 pl-0`}> Evento</div>
                : null}
            </div>
          </div>
        </a>
      </OverlayTrigger>
    </div>
  );

}

const TabButton = ({ name, label, helpMessage, actionFilters, onClickFilterTab, onClickFilterRemove }) => {
  const isActive = actionFilters.includes(name)
  return (
    <HelpMessage message={helpMessage}>
      <button className={"d-flex align-items-center rounded " + (isActive ? "active" : "")}
        style={{ paddingLeft: "0.75rem", paddingRight: (isActive ? "0.4rem" : "0.75rem"), whiteSpace: "nowrap" }}
        onClick={() => onClickFilterTab(name)}>
        {label}
        <i class="ml-2 fas fa-times-circle" onClick={() => onClickFilterRemove(name)}></i>
      </button>
    </HelpMessage>
  )

}



export { ActionElement, TabButton };