import React from "react";
import moment from "moment";
import { executionStatus } from "components/start/executions/util/executions";

const ExecutionDetailsView = ({
  idx,
  execution,
  isDeveloperMode,
  onResumeExecution,
  onCancelExecution
}) => (
  <div className="w-100">
    <div
      class="d-flex justify-content-between align-items-center pr-2 py-2 w-100 mb-1"
      style={{
        paddingLeft: "1.4rem",
        border: "1px solid #E7EAF3",
      }}
    >

      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-start my-2" style={{ maxWidth: "calc(100% - 120px)" }}>
          <i className={`${executionStatus[execution.status]?.icon} ${executionStatus[execution.status]?.color} mt-1`} />
          <div className="mx-3 w-100">
            <div className="w-100 overflow-hidden pr-1">
              <div className="h5 text-truncate">

                {
                  function () {

                    const steps = execution.executionSteps ?? [];
                    const lastStep = steps[steps.length - 1];
                    const uiSettints = (lastStep?.flowNode?.action?.uiSettings ?? {});
                    const title = uiSettints?.node?.title ?? "N/A";

                    return execution.flow.name + " - " + title;

                  }()
                }

                {
                  !execution.isRead && (
                    <span className="badge badge-pill badge-primary ml-2">Nuevo</span>
                  )
                }

              </div>
            </div>
            <div className="h6 font-weight-light mb-0 text-truncate">
              {moment(execution.startDate).format(
                (new Date(execution.startDate)).toDateString() == (new Date()).toDateString()
                  ? "HH:mm" : "DD.MMM")}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column pl-3">
          {
            execution.status == "AWAITING" && (
              <>
                <button
                  className="btn btn-sm btn-primary mb-1 py-1 px-0"
                  onClick={(e) => { e.stopPropagation(); onResumeExecution(execution); }}
                  style={{ width: "5.1rem" }}
                >
                  Reanudar
                </button>
                <button
                  className="btn btn-sm btn-lighter py-1 px-0"
                  onClick={(e) => { e.stopPropagation(); onCancelExecution(execution); }}
                  style={{ width: "5.1rem" }}
                >
                  Cancelar
                </button>
              </>
            )
          }
          {
            execution.status != "AWAITING" && (
              <>
                <button
                  className="btn btn-sm btn-outline-primary mb-1 mr-2"
                  data-toggle="collapse"
                  data-target={"#exec_" + idx}
                  aria-expanded="false"
                  aria-controls={"exec_" + idx}
                >
                  Ver detalle
                </button>
              </>
            )
          }
        </div>
      </div>

    </div>

    <div class="collapse mx-2" id={"exec_" + idx}>
      <span class="dropdown-header pt-2 pb-2"><span style={{ color: "#777" }}>Pasos</span></span>

      <ul class="border-left mr-3 ml-2">
        {execution.executionSteps.map((step, j) => (
          <li key={j} className="d-flex flex-column mb-3">
            <div
              className="d-flex justify-content-between align-items-center py-1 w-100"
              data-toggle="collapse"
              data-target={"#exec_" + idx + "step_" + j}
              aria-expanded="false"
              aria-controls={"exec_" + idx + "step_" + j}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="d-flex align-items-start">
                <i class={`${executionStatus[execution.status]?.icon} ${executionStatus[step.status]?.color} mt-2`} />
                <div className="mx-3">
                  <div>
                    <span className="h6">
                      {step.step + 1}. {(step?.flowNode?.action?.uiSettings ?? {})?.node?.title}
                    </span>
                  </div>
                  <div className="h6 font-weight-light">
                    {moment(step?.startDate).format(
                      (new Date(step?.startDate))
                        .toDateString() == (new Date()).toDateString() ? "HH:mm" : "DD.MMM")}
                  </div>
                </div>
              </div>
            </div>
            {isDeveloperMode ?
              <div class="collapse mx-3" id={"exec_" + idx + "step_" + j}>

                {
                  step.status === "FAILED" && (
                    <div
                      className="d-flex align-items-center text-danger mx-3"
                      style={{
                        cursor: "pointer",
                        userSelect: "none"
                      }}
                    >
                      <i class="fas fa-exclamation-triangle mx-2" />
                      {execution?.exception?.details}
                    </div>
                  )
                }

                <div class="mt-2">Entradas</div>

                <table className="table table-bordered table-hover table-sm m-0 p-0">
                  <thead>
                    <tr>
                      {
                        (step?.flowNode?.inputValue ?? []).map((input, k) => (
                          <th key={input.name} className="text-center">
                            {input.name} ({input.type})
                          </th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {
                        (step?.flowNode?.inputvalue ?? []).map((input, k) => (
                          <th key={input.name} className="text-center">
                            {
                              input?.value?.toString().startsWith("#")
                                ? <b>{input.value}</b>
                                : input.value.toString()
                            }
                          </th>
                        ))
                      }
                    </tr>
                  </tbody>
                </table>

                {
                  (step?.flowNode?.outputValue ?? []).length > 0 && (
                    <>
                      <div class="mt-2">Salidas</div>
                      <table className="table table-bordered table-hover table-sm m-0 p-0">
                        <thead>
                          <tr>
                            {
                              (step?.flowNode?.outputValue ?? []).map((input, k) => (
                                <th key={input.name} className="text-center">
                                  {input.name} ({input.type})
                                </th>
                              ))
                            }
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {
                              function () {

                                const output = step?.result ?? {};
                                const actionOutput = (step?.flowNode?.outputValue ?? []);

                                return actionOutput.map((input, k) => {
                                  let value = output[input.value.replace("#", "")];
                                  return <th key={input.name} className="text-center">
                                    <b>{input.value}</b>
                                    <i className="fas fa-arrow-right mx-1" />
                                    {value?.toString()}
                                  </th>
                                })

                              }()
                            }
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )
                }

              </div> : null}
          </li>
        ))
        }

      </ul>

    </div>
  </div>
);

export default ExecutionDetailsView;