import React from "react";
import ReactCardFlip from "react-card-flip";
import SchemaForm from "components/schema_form/SchemaForm";
import EditSubmitComponent from "components/flow/board/action_form/NodeForm.sub.EditSubmit.view";
import { HelpComponent } from "components/schema_form/fields/commons";
import "styles/css/NodeForm.css";


const getActionTitle = (action) => {
  let nodeIcon = action.app.uiSettings.icon
  let nodeTitle = action.uiSettings.form?.title ?? action.uiSettings?.general?.title;
  let nodeSubtitle = action.uiSettings.form?.subtitle ?? action.uiSettings?.general?.subtitle;
  let nodeApp = action.app.uiSettings.label;
  return (
    <div>
      {nodeTitle}
      <small className="ml-1" style={{ color: "#aaacb0" }}>
        {nodeSubtitle}
      </small>
      <small className="ml-2 px-2 py-1" id="app-detail">
        <i id="app_detail_icon" className={nodeIcon + " mr-1"}></i> {nodeApp}
      </small>
    </div>
  );
}

const NodeFormView = ({
  node = {},
  action,
  flowId,
  initialData,
  isEditing = false,
  isAnnotation = false,
  ignoreSamplePicker,
  isDetailsShown = false,
  onDeleteNode = () => { },
  onOpenDetail = () => { },
  onCloseDetail = () => { },
  onSubmit,
}) => (
  <>
    <ReactCardFlip isFlipped={isDetailsShown}>

      <div className="card" id="node-form">

        <div className="card-header">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="node-form-title mb-0">
                <span>{!isEditing ? "Crear acción" : "Editar acción"}</span>
                {getActionTitle(action)}
              </h3>

              {/* Flip icon to show detils about the action */}
              <div className="mr-4" id="flip-icon">
                <i class="tio-magic-wand" style={{fontSize: "1.85rem"}}
                  onClick={() => isDetailsShown ? onCloseDetail() : onOpenDetail()}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pt-5">
          <div id="node-form-schema">
            <SchemaForm
              schema={action.uiSettings?.form?.fields ?? []}
              appId={action.app.id}
              actionId={action.id}
              flowId={flowId}
              initialValues={initialData}
              ignoreSamplePicker={ignoreSamplePicker}
              submitComponent={isEditing ? () => <EditSubmitComponent onDeleteNode={onDeleteNode} /> : null}
              submitText={!isEditing ? "Crear acción" : null}
              onSubmit={onSubmit}
            />
          </div>

        </div>

      </div>

      <div className="card bg-primary" id="node-form-details">

        <div className="card-header bg-primary">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="node-form-title mb-0">
                <span>Detalles</span>
                {getActionTitle(action)}
              </h3>
              {/* Flip icon to show detils about the action */}
              <div className="mr-4">
                <i class="fa-solid fa-reply-all" id="flip-details-icon"
                  onClick={() => isDetailsShown ? onCloseDetail() : onOpenDetail()}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <ul class="list-group mb-5">

            <div id="details-content" className="d-flex justify-content-between align-items-stretch">

              <div>
                <div className="mb-5" dangerouslySetInnerHTML={{ __html: action.uiSettings?.general?.detail_caption }}></div>
                <div dangerouslySetInnerHTML={{ __html: action.uiSettings?.general?.usage_caption }}></div>
              </div>

              <div>

                {action.uiSettings?.general?.caption_images ? (
                  <div id="carouselActionUsageExample" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                      {action.uiSettings?.general?.caption_images.map((_, idx) => (
                        <li data-target="#carouselActionUsageExample" key={idx} data-slide-to={`${idx}`}
                          class={`${idx == 0 ? "active" : ""}`}></li>
                      ))}
                    </ol>
                    <div class="carousel-inner">
                      {action.uiSettings?.general?.caption_images.map((caption_image, idx) => (
                        <div class={`carousel-item ${idx == 0 ? "active" : ""}`} key={idx}>
                          <img src={caption_image?.url ?? ""} class="d-block w-100" alt="..." />
                          {caption_image?.title || caption_image?.subtitle ? (
                            <div class="carousel-caption d-none d-md-block">
                              <h5>{caption_image?.title}</h5>
                              <p>{caption_image?.subtitle}</p>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <button class="carousel-control-prev" type="button" data-target="#carouselActionUsageExample" data-slide="prev" style={{ border: "none" }}>
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-target="#carouselActionUsageExample" data-slide="next" style={{ border: "none" }}>
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </button>
                  </div>
                ) : ""}
              </div>

            </div>
          </ul>
        </div>
      </div>
    </ReactCardFlip>

  </>
)

export default NodeFormView;