import React from "react";
import { getFlowName } from "components/util/flow";


const getClassNameTemplateType = (templateType) => {
    if (templateType == "SAMPLE") return "badge bg-soft-success text-success";
    if (templateType == "COMPLETE") return "badge bg-soft-primary text-primary";
    return "";
}

const getTextTemplateType = (templateType) => {
    if (templateType == "SAMPLE") return "Parcial";
    if (templateType == "COMPLETE") return "Completo";
    return "";
}

const getClassNameTemplateComplexity = (templateComplexity) => {
    if (templateComplexity == "ADVANCED") return "badge bg-soft-danger text-danger ml-1";
    if (templateComplexity == "INTERMEDIATE") return "badge bg-soft-info text-info ml-1";
    if (templateComplexity == "BASIC") return "badge bg-soft-success text-success ml-1";
    return "";
}

const getTextTemplateComplexity = (templateComplexity) => {
    if (templateComplexity == "ADVANCED") return "Avanzado";
    if (templateComplexity == "INTERMEDIATE") return "Intermedio";
    if (templateComplexity == "BASIC") return "Básico";
    return "";
}



const TemplateItem = ({ idx, flow, selectedProfile, isActive, onCloneTemplate }) => (
    <div key={flow.id} className="card card-hover-shadow mb-0 pr-1 pl-0 py-0 mr-1"
        onClick={() => onCloneTemplate(flow.id)}
        style={{
            minWidth: "15.7rem",
            border: "1px solid #dae0e5",
            cursor: "pointer"
        }}>
        <div className="card-body pt-3 pb-3">
            <div className="d-flex">
                <div class="mr-3 mt-3">
                    <img style={{ width: "2.1rem" }} src="/resources/images/icon-45.svg" alt="Flow" />
                </div>
                <div>
                    <div className="mb-2">
                        <span class={getClassNameTemplateType(flow?.templateSettings?.template_type ?? "")}>
                            {getTextTemplateType(flow?.templateSettings?.template_type ?? "")}
                        </span>

                        <span class={getClassNameTemplateComplexity(flow?.templateSettings?.template_complexity ?? "")}>
                            {getTextTemplateComplexity(flow?.templateSettings?.template_complexity ?? "")}
                        </span>
                    </div>
                    <h5 className="mb-0 text-truncate d-inline-block"
                        style={{ maxWidth: "9.7rem", lineHeight: "1" }}>
                        {flow?.displayName ?? getFlowName(selectedProfile, flow)}
                    </h5>
                    <div>
                        {function () {
                            const templateCategories = flow?.templateCategories ?? [];
                            if (templateCategories.length > 0) {
                                const templateCategory = templateCategories[0];
                                return (
                                    <span class="badge bg-soft-primary text-primary" key={templateCategory.id} style={{ fontSize: "0.7rem" }}>
                                        {templateCategory?.uiSettings?.display?.title}
                                    </span>
                                );
                            }
                            return <></>;
                        }()}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default TemplateItem;