import React from "react";
import { ResultsList, ResultsListType } from "components/flow/suggestions/Results.view.lib.list";
import ResultDetails from "components/flow/suggestions/Results.view.lib.details";

function ResultsView({ 
  pages = [],
  response = {},
  generatedSuggestions = [],
  savedSuggestions = [],
  historySuggestions = [],
  selectedSuggestion = {},
  setPages,
  onChangeSuggestion,
  onSelectSuggestion,
  onRegenerateResponse,
  onClose
}) {
  return <div 
    
    style={{ 
      background: "#f8fafe", 
      marginTop: "12px",
    }}
  >
    <div 
      className="bg-white" 
      
      style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "1.25rem",
        paddingRight: "1.15rem",
        borderRadius: "0.55rem", 
        border: "1px solid rgb(218, 221, 224)",
      }}
    >

      <i 
        class="fas fa-times-circle text-center"
        
        style={{ 
          position: "relative", 
          top: "-0.5rem", 
          left: "100%",
          marginLeft: "-0.5rem",
          width: "22px", 
          height: "20px", 
          fontSize: "1.4em", 
          color: "#c0c5ca", 
          cursor: "pointer" 
        }}
        onClick={onClose}
      />

      {
        pages.length > 0 &&
          <i 
            className="fas fa-arrow-left text-primary" 
            
            style={{ cursor: "pointer", position: "relative", top: "-0.5rem", left: "-1rem" }}
            onClick={() => setPages(pages.slice(0, -1))}
          />
      }

      <div 
        
        className="scrollbar-narrow mt-n3"
        style={{
          height: "21rem",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",          
        }}
      >

        {
          pages.length == 0 
            ? <>

                <div  className="h5">

                  {
                    generatedSuggestions.length == 0 && !response?.response?.answer &&
                      "No ha sido posible generar sugerencias. Por favor, intenta de nuevo."
                  }

                  { 
                    response?.response?.answer && generatedSuggestions.length == 0
                      ? <>
                          <div >{response.response?.question}</div>
                          <div  className="text-muted">{response.response?.answer}</div>
                        </>
                      : null
                  }

                  {
                    generatedSuggestions.length > 0 && <>
                      <div >{generatedSuggestions[0].description}</div>                      
                    </>
                  }

                </div>

                {
                  generatedSuggestions.length > 0 &&
                    <>
                      <hr  className="mt-3 mb-2"/>
                      <ResultsList
                        type={ResultsListType.GENERATED}
                        suggestions={generatedSuggestions}
                        onChangeSuggestion={(suggestion) => 
                          onChangeSuggestion({ id: suggestion.id, isFavorite: true })}
                        onSelectSuggestion={onSelectSuggestion}
                      />
                      <button
                        
                        type="button"
                        className="btn btn-sm p-0"
                        onClick={onRegenerateResponse}
                      >
                        <i class="fas fa-undo mr-1 ml-1"></i> Más ideas
                      </button>

                    </>
                }

                {/* {
                  (historySuggestions.length > 0 || savedSuggestions.length > 0) &&
                    <div 
                      className="d-flex w-100 justify-content-center text-primary my-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages([...pages, "history"])}
                    >
                      Ver historial
                    </div>
                } */}

              </>
            : <>

                {
                  pages[pages.length - 1] == "history" &&
                    <>
                      <ResultsList
                        type={ResultsListType.SAVED}
                        suggestions={savedSuggestions}
                        onChangeSuggestion={(suggestion) => 
                          onChangeSuggestion({ id: suggestion.id, isFavorite: false })}
                        onSelectSuggestion={onSelectSuggestion}
                      />

                      {
                        savedSuggestions.length > 0 &&
                          <hr className="mt-3 mb-2"/>
                      }

                      <ResultsList
                        type={ResultsListType.HISTORY}
                        suggestions={historySuggestions}
                        onChangeSuggestion={(suggestion) => 
                          onChangeSuggestion({ id: suggestion.id, isShown: true })}
                        onSelectSuggestion={onSelectSuggestion}
                      />
                    </>
                }

                {
                  pages[pages.length - 1] == "details" && 
                    <ResultDetails suggestion={selectedSuggestion} />
                }

              </>
        }

      </div>
    </div>
  </div>
}

export default ResultsView;