import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';


const UseCaseCategory = ({ idx, category, isActive, onChangeProfile }) => (
    <div>
        <OverlayTrigger placement="bottom" delay={{ show: 100 }}
            overlay={
                <Popover id="toolbox-action-popover">
                    <Popover.Body className="p-3"
                        style={{
                            backgroundColor: "#f0f3f5", border: "1px solid #e0e5ea",
                            color: "#40454a", borderRadius: "10px", maxWidth: "12rem"
                        }}>
                        <div>{category?.uiSettings?.display?.title}</div>
                    </Popover.Body>
                </Popover>
            }>
            <div className="mx-2" style={{ cursor: "pointer" }} onClick={() => onChangeProfile(category)}>
                <span className={`icon icon-lg icon-circle ${isActive ? "icon-primary text-white" : "icon-light text-primary"}`}
                    style={{ border: "2px solid white" }}
                >
                    <i className={category?.uiSettings?.display?.icon}></i>
                </span>
            </div>
        </OverlayTrigger>
    </div>
)

export default UseCaseCategory;