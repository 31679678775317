import React, { useState, useRef } from "react";
import swal from "sweetalert";
import { usePost } from "seed/api";
import { useQuery } from "seed/gql";
import View from "components/start/templates/Templates.view";

function Templates() {

  const userId = sessionStorage.getItem("id");
  const searchFieldRef = useRef(null);
  const [flows, setFlows] = useState([]);
  const [categories, setCategories] = useState([]);

  const [selectedProfile, setSelectedProfile] = useState(null);

  const [callCloneTemplate, reqCloneTemplate] = usePost('/flows/clone_template', {
    onCompleted: (data) => {
      const clonedFlowId = data?.cloned_flow_id;
      if (clonedFlowId) {
        window.location.replace(`/app/flow/${clonedFlowId}/flow`);
      }
    },
    onError: (error) => {
      console.log(error);
      swal("Error al usar plantilla", "Ha ocurrido un error inesperado, por favor intente de nuevo mas tarde", "error")
    }
  }) 

  const queryFlowTemplates = `isTemplate=True ${selectedProfile ? `AND templateCategories.id=${selectedProfile.id}` : ""}`;
  const reqFlows = useQuery(`{
    flows {
      name
      createdAt
      description
      isTemplate
      templateName
      templateSettings
      templateCategories {
        name
        description
        uiSettings
      }
    }
  }`, queryFlowTemplates, {
    onCompleted: (data) => {
      const newFlows = data?.flows ?? [];
      setFlows([...newFlows])
    }
  });

  const reqCategories = useQuery(`{
    templateCategories {
      name
      description
      uiSettings
    }
  }`, "", {
    onCompleted: (data) => {
      setCategories(data?.templateCategories ?? []);
    }
  })

  const onSearchTemplates = () => {

    if (searchFieldRef.current == null || searchFieldRef.current == undefined)
      return;

    const searchPrompt = (searchFieldRef.current?.value ?? "").toLowerCase().split(' ');
    const sampleFlows = [...flows.filter(flow => flow?.templateSettings?.template_type == "SAMPLE")];

    const coincidences = sampleFlows.reduce((results, flow) => {
      const name = flow.templateSettings?.name ?? {};
      const matchingNames = Object.entries(name)
        .filter(([key, value]) => {
          const lowerValue = value.toLowerCase();
          return searchPrompt.every(term => lowerValue.includes(term));
        })
        .map(([key, value]) => ({ category: key, name: value }));

      if (matchingNames.length > 0) {
        results.push({
          id: flow.id,
          matches: matchingNames
        })
      }

      return results;
    }, []);

    const filteredFlows = coincidences.map((coincidence) => {
      const flow = {...sampleFlows.find(flow => flow.id == coincidence.id)};
      if (coincidence.matches.length > 0) {
        flow.displayName = coincidence.matches[0].name;
      }
      return flow;
    });

    setFlows([...filteredFlows]);

  }

  const onClearSearch = () => {
    setSelectedProfile(null);
    reqCategories.refetch();
    reqFlows.refetch();
  }

  const onChangeProfile = (newProfile) => {
    setSelectedProfile(newProfile);
  }

  const onCloneTemplate = (flowId) => {
    callCloneTemplate({
      flow_id: flowId,
      user_id: userId,
      template_category_id: selectedProfile?.id
    })
  }

  return (
    <View
      templateFlows={flows}
      templateCategories={categories}
      selectedProfile={selectedProfile}
      searchFieldRef={searchFieldRef}
      onChangeProfile={onChangeProfile}
      onCloneTemplate={onCloneTemplate}
      onSearchTemplates={onSearchTemplates}
      onClearSearch={onClearSearch}
    />
  );
}

Templates.propTypes = {};

export default Templates;