
import React from "react";
import { Link } from "react-router-dom";
import ExecModeCharts from "components/flow/exec_mode/panels/Charts";
import ExecModeTable from "components/flow/exec_mode/panels/Data";
import ExecModeForm from "components/flow/exec_mode/panels/Form";
import HelpMessage from 'components/util/helpers/HelpMessage';

const ExecModeView = ({
  flow,
  execModeSettings,
  activeTab,
  setActiveTab,
  onExecuteNode,
}) => (
  <div className="card py-4 px-5 animate__animated animate__fadeIn animate__faster"
    style={{
      height: "calc(100% - 90px)",
      border: "1px solid #e7eaf3",
      background: "#f8fafe",
      borderRadius: "0.375rem",
    }}
  >

    {execModeSettings.length > 1 ?
      <div className="d-flex justify-content-start pb-4">
        {
          execModeSettings.length > 1 && execModeSettings.map((execModeSetting, index) => (
            <button
              key={index}
              className={`btn dropdown-header text-center text-truncate px-2 mr-2 exec-mode-tab ${activeTab === index ? "active" : ""}`}
              style={{ width: "10rem" }}
              onClick={() => setActiveTab(index)}
            >
              {execModeSetting.title}
            </button>
          ))
        }
      </div> : null}

    <div className="d-flex justify-content-between">
      <div className="h1 ml-1">
        {
          execModeSettings.length > 1
            ? execModeSettings[activeTab]?.title
            : flow.name
        }
      </div>
      <div>

        {
          function () {

            const execModeSetting = execModeSettings[activeTab] ?? {};
            const leftPanel = execModeSetting.left_panel ?? "";
            const rightPanel = execModeSetting.right_panel ?? "";

            if (leftPanel == "ACTIVITY_CHART" || rightPanel == "ACTIVITY_CHART")
              return;

            return <HelpMessage message="Visualiza la actividad de tu proceso">
              <Link to="/flow/activity" className="btn btn-sm btn-lighter-white mr-2"
                style={{ paddingRight: "0.85rem", paddingLeft: "0.85rem" }}>
                <i className="fas fa-chart-line "></i> Actividad
              </Link>
            </HelpMessage>

          }()
        }

        <HelpMessage message="Configura las opciones de visualización">
          <Link to="/exec_mode/settings" className="btn btn-sm btn-primary mr-2"
            style={{ paddingRight: "0.85rem", paddingLeft: "0.85rem" }}>
            <i class="fas fa-pen-fancy mr-1"></i> Personalizar
          </Link>
        </HelpMessage>

      </div>
    </div>

    <div
      className="row pt-3 h-100"
      style={{ overflowY: "auto" }}
    >
      {
        function () {

          const execModeSetting = execModeSettings[activeTab] ?? {};
          const leftPanel = execModeSetting.left_panel ?? "";
          const rightPanel = execModeSetting.right_panel ?? "";

          const getPanel = (panel, isFullWidth = false) => {

            if (panel === "FORM")
              return <ExecModeForm
                flow={flow}
                execModeSetting={execModeSetting}
              />

            if (panel === "TABLE")
              return <ExecModeTable
                flow={flow}
                execModeSetting={execModeSetting}
              />

            if (panel === "ACTIVITY_CHART")
              return <ExecModeCharts
                flow={flow}
                isFullWidth={isFullWidth}
                execModeSetting={execModeSetting}
                onExecuteNode={onExecuteNode}
              />

          }

          if (leftPanel == "EMPTY" || rightPanel == "EMPTY")
            return <div className="col-lg-12 h-100">
              <div
                className="border rounded w-100 h-100 bg-white"
                style={{
                  maxHeight: "100%",
                  overflowY: "hidden",
                }}
              >
                {getPanel(leftPanel, true)}
                {getPanel(rightPanel, true)}
              </div>
            </div>

          return <>
            <div className="col-lg-6 h-100 mt-3 mt-lg-0 pr-2">
              <div className="border rounded w-100 h-100 bg-white">
                {getPanel(leftPanel)}
              </div>
            </div>
            <div className="col-lg-6 h-100 mt-3 mt-lg-0 pl-2">
              <div className="border rounded w-100 h-100 bg-white">
                {getPanel(rightPanel)}
              </div>
            </div>
          </>

        }()
      }
    </div>

  </div>
)

export default ExecModeView;