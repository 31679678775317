import React, { useState } from "react";
import { useDetail } from "seed/gql";
import View from "components/start/Start.view";
import { Loading } from "seed/helpers";

function Start({ uuid }) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";
  const userId = sessionStorage.getItem("id");
  sessionStorage.setItem("uuid", uuid);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isExecutionsShown, setIsExecutionsShown] = useState(false);


  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
    }
  }`, userId);

 
  const onClickLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/logout");
  }

  if (reqUser.loading ) return <Loading />;
  if (reqUser.error) return "Error";

  const user = reqUser?.data?.user ?? {};

  return (
    <View
      user={user}
      selectedFolder={selectedFolder}
      isExecutionsShown={isExecutionsShown}
      isDeveloperMode={isDeveloperMode}
      setSelectedFolder={setSelectedFolder}
      setIsExecutionsShown={setIsExecutionsShown}
      onClickLogout={onClickLogout}
    />
  );
}

export default Start;