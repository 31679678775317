import React, { useState, useEffect, useRef } from 'react';
import SchemaForm from 'components/schema_form/SchemaForm';
import { usePost } from 'seed/api';

const ExecModeForm = ({ execModeSetting }) => {

  const [callSendForm] = usePost("/apps/forms/send_form");
  const [refresh, setRefresh] = useState(false);
  const submitRef = useRef(null);

  const onFormSubmit = (data) => {
    callSendForm({
      response: data,
      node_id: execModeSetting.root.id,
      user_id: sessionStorage.getItem("id"),
    });
    setRefresh(true);
  }

  useEffect(() => {
    if(refresh) setRefresh(false);
  }, [refresh]);

  if(refresh) 
    return <></>;

  return <div 
    class="card border-0 h-100"
    style={{
    }}
  >
    <div 
      class="card-body pt-0 pb-0" 
      style={{ overflowY: "auto" }}
    >
      <h3 class="my-3">Completar Formulario</h3>
      <SchemaForm 
        onSubmit={onFormSubmit}
        schema={execModeSetting.root.schema}
        appId={execModeSetting.root.app_id}
        actionId={execModeSetting.root.action_id}
        flowId={execModeSetting.root.flow_id}
        nodeId={execModeSetting.root.id}
        submitComponent={() => 
          <button
            ref={submitRef}
            style={{ display: "none" }}
          />
        }
        submitText="Enviar" 
      />
    </div>
    <div class="card-footer">
      <button 
        class="btn btn-block btn-success"
        onClick={() => submitRef.current.click()}
      >
        Enviar
      </button>
    </div>
  </div>;

}

export default ExecModeForm;