import React from "react";
import lodash from "lodash";
import BaseField from "components/schema_form/fields/custom_fields/BaseField";
import { HelpComponent } from "components/schema_form/fields/commons";
import InputComponent from "components/schema_form/SchemaForm.sub.InputComponent.view";


const InputHandler = (props) => {

  const input = props.input;
  const nextInput = props.nextInput;
  const multiple = input.multiple ?? false;
  const minFields = input.min_fields ?? 1;
  const maxFields = input.max_fields ?? 100;
  
  const name = input.name;
  const type = input.type;
  const label = input.label;
  const hidden = input?.hidden ?? false;
  const disabled = input?.disabled ?? props.isReadOnly;
  let required = input?.required ?? true;
  if (hidden == true) required = false;

  const caption = input?.caption ?? ""
  const helpMessage = input?.help_message ?? "";
  const parentKey = props.parentKey;

  const values = props.values
  const setValues = props.setValues

  const sectionIdx = props.sectionIdx

  const key = parentKey == "" ? name : `${parentKey}.${name}`
  const keyValue = lodash.get(values, key)
  const parentValue = lodash.get(values, parentKey)

  return <li className="step-item" style={{ marginBottom: nextInput == null || nextInput?.label ? "1.5rem" : "0rem" }}>
    <div className="step-content-wrapper">
      <span className="step-icon step-icon-pseudo step-icon-soft-dark ml-2"></span>
      <div className="step-content mr-4 pt-0"
        style={{ paddingBottom: nextInput == null || nextInput?.label ? "0.7rem" : "0.4rem", marginLeft: "0.85rem" }}>
        {
          (() => {
            if (multiple) {
              const defaultArray = new Array(minFields).fill(null);
              return (
                <div>
                  {(keyValue ?? defaultArray).length == 0 ?
                    type == "SECTION" ?
                      <div className="w-100 mt-1">
                        <a class="d-flex mb-1 w-100 justify-content-between" data-toggle="collapse" href={"#"}
                          style={{ cursor: "default" }}>
                          {label != "" ?
                            <span class="dropdown-header p-0">
                              <span style={{ color: "#777" }}>{label}</span>
                              {helpMessage != null ? <HelpComponent helpMessage={helpMessage} /> : null}
                            </span> : null}
                        </a>
                        <hr class="mt-2 w-100" style={{ border: "none", height: "1.5px", background: "#e7eaf3" }} />
                      </div>
                      :
                      <BaseField
                        name={name} label={label}
                        required={required} caption={caption} helpMessage={helpMessage} ><div></div></BaseField>
                    : null}

                  {(keyValue ?? defaultArray).map((value, idx) => {
                    let newInput = {
                      ...input, name: `${name}[${idx}]`,
                      label: idx == 0 || type == "SECTION" ? label : ""
                    }
                    return <div key={value} class="d-flex align-items-start justify-content-between mb-1 mt-n1">
                      <div className={"d-flex"} style={{ width: "92%" }}>
                        {(keyValue ?? defaultArray).length > 1 ? 
                          <span className="mr-1 mt-1"
                            style={{fontSize: "0.72em", color: "#80858a", background:"#f0f0f0", 
                            height: "1rem", padding: "0rem 0.3rem", borderRadius: "50%"}}>{idx+1}</span> : null}
                        <div className="w-100">
                          <InputComponent {...props} input={newInput} sectionIdx={sectionIdx??idx} />
                        </div>
                      </div>
                      <div class="text-right" style={{ width: "8%" }}>

                        {!disabled &&  idx >= minFields ?
                          <button type="button" class="btn btn-link px-1"
                            onClick={() => {
                              let tempVal = {}
                              let tempVals = keyValue ?? defaultArray
                              tempVals.splice(idx, 1)
                              tempVal[name] = tempVals
                              setValues({ ...values, ...tempVal });
                            }}
                            style={{ color: "#c0c5d5", fontSize: "1.15em" }}>
                            <i class="fas fa-minus-circle"></i>
                          </button> : null}
                      </div>
                    </div>
                  })}

                  {!disabled && (keyValue ?? []).length < maxFields ?
                    <button type="button" class="btn btn-link pl-0 mt-1 pt-1"
                      onClick={() => {
                        let tempVal = {}
                        tempVal[name] = [...(keyValue ?? defaultArray), ...[null]]
                        if (parentKey == "") setValues({ ...values, ...tempVal });
                        else setValues(lodash.set(values, `${parentKey}`, { ...parentValue, ...tempVal }))
                      }}
                      style={{ color: "#777", fontWeight: "normal", fontSize: "0.96em" }}>
                      <div className="d-flex align-items-center">
                        <i class="fas fa-plus"></i>&nbsp;Agregar  
                        {label && label != "" && type == "SECTION" ? <span className="ml-1 d-inline-block text-truncate" style={{maxWidth: "10rem"}}>&quot;{label}&quot;</span> : null }
                      </div>
                    </button> : null}
                </div>
              )
            }

            return <InputComponent {...props} sectionIdx={sectionIdx}/>
          })()
        }
      </div>
    </div>
  </li>
}

export default InputHandler;