/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import Apps from "components/flow/toolbox/apps/Apps";
import { ActionElement, TabButton } from "components/flow/toolbox/Toolbox.sub.ActionElement.view"
import * as util from "components/flow/toolbox/util/toolbox";
import { StyleTag, ModalRoute } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";
import "styles/css/Toolbox.css";


const ToolboxView = ({
  apps,
  flow,
  user,
  filterTabs,
  suggestions,
  actionSearch,
  actionFilters,
  isDisabled,
  isAnonymous,
  isSuggestionsLoading,
  reqApps,
  onSearchActions,
  onClickSearchRemove,
  onDragStart,
  onClickAction,
  onClickFilterTab,
  onClickFilterRemove
}) => (

  <div id="toolbox-container" className="d-md-block">

    {isDisabled && <div id="disabling-frame" />}

    <div id="toolbox-main">

      <div className="search-wrapper" id="toolbox-search">
        <input type="text" placeholder="Buscar acciones" class="py-1 pl-5 pr-5 rounded w-100 search-input"
          value={actionSearch} onChange={(event) => onSearchActions(event.target.value)}
        />
        <i className="fas fa-search d-block ml-2 search-icon"></i>
        {actionSearch != "" ?
          <i class="fas fa-times search-close py-0 px-2"
            onClick={onClickSearchRemove}
          ></i> : null}
      </div>

      <div id="toolbox-tabs">
        <ScrollArrow
          scrollAmount={150}
          backgroundFadeColor={{ red: 255, green: 255, blue: 255 }}
          leftButtonStyle={{
            paddingLeft: "2px",
            paddingRight: "2px"
          }}
          rightButtonStyle={{
            paddingLeft: "2px",
            paddingRight: "2px"
          }}
        >
          {filterTabs.map(filter =>
            <TabButton
              key={filter.name}
              name={filter.name}
              label={filter.label}
              helpMessage={filter?.helpMessage ?? ""}
              actionFilters={actionFilters}
              onClickFilterTab={onClickFilterTab}
              onClickFilterRemove={onClickFilterRemove}
            />
          )}
        </ScrollArrow>
      </div>

      <div id="toolbox-actions"
        className={"w-100 scrollbar-narrow d-flex px-3 " + (actionSearch != "" || actionFilters.length > 0 ? "flex-column" : "flex-wrap")}
      >

        {/*  SUGGESTIONS */}
        {!isSuggestionsLoading && suggestions.length > 0 ? (
          <div id="toolbox-suggestions" class="card mx-3 my-2 pb-1">
            <span class="dropdown-header pt-3 pb-1">
              <span>
                <i class="fa-solid fa-star mr-2"></i>Sugerencias
              </span>
            </span>
            <ul className="list-group py-2 w-100">
              {suggestions.map((suggestion, idx) => {
                return (
                  <ActionElement
                    key={suggestion.id}
                    action={suggestion}
                    onClickAction={onClickAction}
                    onDragStart={onDragStart}
                    isSuggestion={true}
                  />
                );
              })}
            </ul>
          </div>
        ) : null}

        {actionSearch != "" || actionFilters.length > 0 ?
          <span class="d-flex justify-content-between dropdown-header pt-3 pb-2 px-2 search-title">
            {actionSearch != "" ? "Búsqueda" : filterTabs.find(tab => tab.name == actionFilters[0])?.label}
          </span> : null}

        {/* ACTIONS */}
        {function () {

          let appsToShow = apps;
          let hasFilters = actionFilters.length > 0;
          const searchCondition = (app, action) =>
            actionSearch == "" ||
            util.getActionName(action).toLowerCase().includes(actionSearch.toLowerCase()) ||
            util.getAppName(app).toLowerCase().includes(actionSearch.toLowerCase()) ||
            (action.uiSettings.general?.keywords ?? []).toString().toLowerCase().includes(actionSearch.toLowerCase()) ||
            (app.uiSettings.keywords ?? []).toString().toLowerCase().includes(actionSearch.toLowerCase())

          const filterCondition = (action) =>
            !hasFilters ||
            (action.uiSettings.general?.categories ?? []).find(category => actionFilters.includes(category))

          let groupIdx = 0
          return appsToShow.map((app, idx) => {
            if (!app) return <div key={idx}></div>;
            let isDeveloper = sessionStorage.getItem("developer_mode") == "true";
            const sectionId = "section-" + app.id
            const isPrevDifferentGroup = (idx > 0 && appsToShow[idx]?.uiSettings?.group_label != appsToShow[idx - 1]?.uiSettings?.group_label)
            const isNextDifferentGroup = (idx < appsToShow.length && appsToShow[idx]?.uiSettings?.group_label != appsToShow[idx + 1]?.uiSettings?.group_label)

            const isEvenSection = groupIdx % 2 == 1 || isNextDifferentGroup
            groupIdx = !isNextDifferentGroup ? groupIdx + 1 : 0

            const getActions = (app) => {
              return app.actions
                .filter((action) =>
                  searchCondition(app, action) &&
                  filterCondition(action) &&
                  ((!action.developerOnly && !action.underDev) || isDeveloper))
                .sort((a, b) => a.relevance - b.relevance)
            }

            const getMainActions = (app) => {
              let actions = getActions(app)
              return actions.filter(action => !(action.isHidden || action.developerOnly || action.underDev) || (actionSearch != "" || hasFilters))
            }

            const getHiddenActions = (app) => {
              let actions = getActions(app)
              return actions.filter(action => (action.isHidden || action.developerOnly || action.underDev) && (actionSearch == "" && !hasFilters))
            }

            const getCollapse = (app) => {
              const sectionId = "section-" + app.id
              const hiddenId = "hidden-" + app.id
              const mainActions = getMainActions(app)
              const hiddenActions = getHiddenActions(app)

              return (
                <div id={sectionId} key={"collapse-" + app.id}
                  className={"collapse w-100 " +
                    (actionSearch != "" || hasFilters ? "show " : "") +
                    (mainActions.length + hiddenActions.length == 0 ? "mt-0 mb-0 " :
                      (actionSearch != "" || hasFilters ? "mt-1 mb-1 pt-0 " : "mt-1 mb-2 pt-1 "))
                  }>
                  <ul className={"actions-list list-group pt-0 w-100 " + (actionSearch != "" || hasFilters ? "pb-0 " : "pb-2 ")}>
                    {mainActions.map((action, idx) => {
                      return (
                        <div key={action.id}>
                          <ActionElement
                            action={action}
                            app={app}
                            onClickAction={onClickAction}
                            onDragStart={onDragStart}
                          />
                          {idx < mainActions.length - 1 &&
                            mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                            <div className="my-3"></div> : null
                          }
                        </div>
                      );
                    })}

                    <div className="hidden-actions">
                      {hiddenActions.length > 0 ?
                        <a
                          class="btn btn-sm py-2 px-3 mt-1 text-left hidden-actions-title" data-toggle="collapse"
                          href={"#" + hiddenId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
                          Opciones avanzadas
                          <i class="ml-2 fas fa-chevron-down"></i>
                        </a> : null}

                      <div class="collapse mt-1 pt-2 mb-2" id={hiddenId}>
                        {hiddenActions.map((action, idx) => {
                          return (
                            <div key={action.id}>
                              <ActionElement
                                action={action}
                                app={app}
                                onClickAction={onClickAction}
                                onDragStart={onDragStart}
                              />
                              {idx < mainActions.length - 1 &&
                                mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                                <div className="my-3"></div> : null
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>

                  </ul>
                </div>
              );
            }

            const title =
              (idx == 0 || appsToShow[idx].uiSettings?.group_label != appsToShow[idx - 1].uiSettings?.group_label) && actionSearch == "" && !hasFilters ?
                <div
                  className={"px-1 w-100 section-title mt-2"}>
                  <hr className="w-100" />
                  <label>{appsToShow[idx].uiSettings?.group_label}</label>
                </div> : null


            const category = (
              <div key={"category-" + app.id} className="btn-category-wrapper apps"
                style={{ display: actionSearch == "" && !hasFilters ? "block" : "none" }}>
                <a
                  class="d-flex justify-content-between pt-3 px-1 w-100 btn-category rounded" data-toggle="collapse"
                  href={"#" + sectionId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
                  <div className="d-flex flex-column justify-content-center w-100" style={{ cursor: "pointer" }}>
                    <div className={"category-icon ml-0 " + app.uiSettings?.icon}></div>
                    <span className="d-inline-block text-truncate category-title">
                      {util.getAppName(app)}
                    </span>
                  </div>
                </a>
              </div>);

            const collapse = isEvenSection ?
              <div className="w-100">
                {!isPrevDifferentGroup ? getCollapse(appsToShow[idx - 1]) : null}
                {getCollapse(app)}
              </div> : null

            return [title, category, collapse]
          })
        }()}

        {
          actionSearch != "" ?
            <div className="text-center mx-2 my-7 no-results">No se encontraron resultados</div> : null
        }
      </div>

      <div className="px-3 my-4">
        <Link to="/flow/apps" id="store-button"
          class="btn btn-sm btn-lighter py-1 w-100" data-toggle="collapse"
        >
          <i class="fa-solid fa-plus mr-1"></i> Más acciones
        </Link>
      </div>

    </div>

    <ModalRoute
      height="600"
      path={"/flow/apps"}
      component={() =>
        <div className="card">
          <div class="card-header d-flex justify-content-between"><h3 class="mb-0">Acciones</h3></div>
          <Apps reqApps={reqApps} flowId={flow.id} />
        </div>}
    />


  </div>
);

export default ToolboxView;
