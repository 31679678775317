import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useGetCall, usePost } from "seed/api";
import { useSet } from "seed/gql";
import { SET_EXECUTION } from "seed/gql/queries";
import View from "components/start/executions/ExecutionsList.view";


function ExecutionsList({ flow = {}, status, user_id, placeholder = "No hay actividad reciente" }) {

  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 5;

  const [filterDate, _] = useState(moment().subtract(7, "days").format("YYYY-MM-DD"));
  const [called, setCalled] = useState(false);

  const [callGetExecutions, reqExecutions] = useGetCall("/executions/get_executions",
    {
      status: status,
      flow_id: flow?.id,
      user_id: user_id,
      page_size: pageSize,
      page_num: pageNum,
      date: filterDate,
    },
    {
      onCompleted: (data) => {
        setCalled(true);
      }
    }
  );

  const [callResume, reqResume] = usePost("/executions/resume_execution", {
    onCompleted: (data) => {
      callGetExecutions();
    }
  });

  const [callCancel, reqCancel] = usePost("/executions/cancel_execution", {
    onCompleted: (data) => {
      callGetExecutions();
    }
  });

  const [callSetRead, reqSetRead] = useSet(SET_EXECUTION);

  useEffect(() => {
    callGetExecutions();
  }, [pageNum]);

  const onResumeExecution = (execution) => {
    history.replace(`/flow/${execution.flow.id}/flow?resume=${execution.id}`);
    callResume({ execution_id: execution.id });
  }

  const onCancelExecution = (execution) => {
    callCancel({ execution_id: execution.id });
  }

  const { executions = [], totalPages = 0 } = reqExecutions.data || {};

  useEffect(() => {
    if (executions && executions.length > 0) {
      executions.filter((execution) => !execution.isRead).forEach((execution) => {
        callSetRead({ id: execution.id, isRead: true });
      });
    }
  }, [executions]);

  let sortedExecutions = executions.map((execution) => {

    let auxExecution = Object.assign({}, execution);
    auxExecution.executionSteps = Object.assign([], execution.executionSteps);
    auxExecution.executionSteps.sort((a, b) => {
      if (a.step < b.step) return -1;
      if (a.step > b.step) return 1;
      return 0;
    });

    return auxExecution;

  });

  if(reqExecutions.loading || !called)
    return <div className="d-md-block" style={{ padding: "0.72rem 0rem", minWidth: "285px" }}>
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    </div>

  return (
    <View
      executions={sortedExecutions}
      totalPages={totalPages > 5 ? 5 : totalPages}
      pageNum={pageNum}
      placeholder={placeholder}
      setPageNum={setPageNum}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
    />
  );

}

ExecutionsList.propTypes = {};

export default ExecutionsList;