import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';


const TemplateCategory = ({ idx, category, isActive, onChangeProfile }) => (
    <div>
        <OverlayTrigger placement="bottom" delay={{ show: 100 }}
            overlay={
                <Popover>
                    <Popover.Body className="p-3 toolbox-action-popover"
                        style={{
                            backgroundColor: "#f0f3f5", border: "1px solid #e0e5ea",
                            color: "#40454a", borderRadius: "10px", maxWidth: "12rem"
                        }}>
                        <div>{category?.uiSettings?.display?.title}</div>
                    </Popover.Body>
                </Popover>
            }>
            <div className="mx-1" style={{ cursor: "pointer" }} onClick={() => onChangeProfile(category)}>
                <span className={`icon icon-circle ${isActive ? "icon-light text-primary" : "icon-primary text-white"}`}
                    style={{ border: "1px solid #2080E5" }}
                >
                    <i className={category?.uiSettings?.display?.icon}></i>
                </span>
            </div>
        </OverlayTrigger>
    </div>
)

export default TemplateCategory;