import React from "react";
import * as Factory from "components/flow/board/nodes/helpers/Factory";


const ControlNode = ({
  app,
  action,
  nodeId,
  nodeInput,
  nodeOutput,
  errors,
  warnings,
  isSelected,
  onEdit,
  onSelectNode
}) => (

  <div className="control-node">
    <div className="text-center drag-handle-container" onClick={onEdit}>

      <div className="d-flex align-items-center control-node-container">
        <div className="node-content">
          <Factory.NodeContent
            app={app}
            action={action}
            nodeInput={nodeInput}
            nodeOutput={nodeOutput}
            isControl={true}
            isSelected={isSelected}
            onSelectNode={onSelectNode}
          />
        </div>
      </div>

      <Factory.TargetPoints action={action} />
      <Factory.HelpPlaceholder nodeId={nodeId} action={action} />
    </div>

    {(warnings.length > 0 || errors.length > 0) && (
      <div className="dropright warnings-errors-control-node">
        <div className="text-center dropdown-toggle dropdown-icons" data-toggle="dropdown" aria-haspopup="true" data-offset="-50,-330">
          {errors.length > 0 ? (
            <div className="errors">
              <i class="fa-solid fa-circle-xmark text-danger"></i>
            </div>
          ) : (
            <div className="warnings">
              <i class="fa-solid fa-triangle-exclamation text-warning"></i>
            </div>
          )}
        </div>

        <Factory.DropdownErrors
          isControl={true}
          warnings={warnings}
          errors={errors}
          actionUiSettings={action.uiSettings}
        />
      </div>
    )}

  </div>
);

export default ControlNode;
