import React from "react";
import * as Factory from "components/flow/board/nodes/helpers/Factory";
import EventInit from "components/flow/board/nodes/helpers/EventInit";
import ControlNode from "components/flow/board/nodes/Node.sub.ControlNode.view";
import "styles/css/Node.css";


const NodeView = ({
  app,
  action,
  nodeId,
  nodeInput,
  nodeOutput,
  nodeData,
  errors,
  warnings,
  executionMessage,
  canNodeBeExecuted,
  isRoot,
  isIterable,
  isSelected,
  onClickExecute,
  onEdit,
  onDeleteNode,
  onSelectNode,
  onChangeStatusTrigger
}) => (
  <div>
    {action.name == "conditional" || action.name == "iterate" ? (
      <ControlNode
        app={app}
        action={action}
        nodeId={nodeId}
        nodeInput={nodeInput}
        nodeOutput={nodeOutput}
        errors={errors}
        warnings={warnings}
        isRoot={isRoot}
        isSelected={isSelected}
        onEdit={onEdit}
        onSelectNode={onSelectNode}
      />
    ) : (
      <div>
        <div className={"dropright " +
          (action.type == "TRIGGER" ? "trigger " : "node ") +
          (isRoot ? "root " : "") +
          (executionMessage != "" ? "has-execute-message " : "") +
          (warnings.length > 0 ? "has-warnings " : "") +
          (errors.length > 0 ? "has-errors " : "")}>

          <div className="node-idx" style={{ bottom: action.type != "TRIGGER" ? "-5px" : "-6px" }}>
            <span>{nodeId}</span>
          </div>

          <div
            className="node-content text-center drag-handle-container" onClick={onEdit}
            style={{ padding: action.type != "TRIGGER" ? "8px 6px" : "5px 8px 2px 8px" }}
          >
            <Factory.NodeContent
              app={app}
              action={action}
              nodeId={nodeId}
              nodeInput={nodeInput}
              nodeOutput={nodeOutput}
              isIterable={isIterable}
              isSelected={isSelected}
              onSelectNode={onSelectNode}
            />
            {action.type == "TRIGGER" && (<hr className="mt-2 mb-1" />)}
          </div>

          {((isRoot && action.type != "TRIGGER") || (action.type == "TRIGGER" && nodeData.is_enabled)) && (
            <div
              style={{
                top: action.type != "TRIGGER" ? "-12.5px" : "-4px",
                left: action.type != "TRIGGER" ? "calc(100% - 15px)" : "calc(100% - 20px)"
              }}
              data-toggle="dropdown" className="dropdown-toggle exec-button" aria-expanded="true" data-display="static"
              onClick={onClickExecute}
            >
              <span class="badge badge-pill badge-success node-execute">
                <i className="fa-solid fa-play"></i> Ejecutar
              </span>
            </div>
          )}

          {/* Execution Message */}
          {(executionMessage != "" && action.type != "TRIGGER" && canNodeBeExecuted) && (
            <div style={{
              top: !isRoot ? "-11px" : "-14px",
              left: !isRoot ? "calc(100% - 4px)" : "calc(100% + 43px)"
            }}
              data-toggle="dropdown exec-message-icon" className="dropdown-toggle" aria-expanded="false" data-display="static"
            >
              <i className="fas fa-question-circle"></i>
            </div>
          )}

          {executionMessage != "" && (
            <div className={`dropdown-menu execution-message ${action.type != "TRIGGER" ? "execution-node" : "execution-trigger"}`}>
              <div dangerouslySetInnerHTML={{ __html: executionMessage }} />
              <EventInit onDeleteNode={onDeleteNode} />
            </div>
          )}

          {/* Switch to activate / deactivate the trigger */}
          {action.type == "TRIGGER" && (
            <div class="trigger-active-switch form-group mb-0 text-center">
              <div class="custom-control custom-switch d-flex mx-3 mt-0" onClick={e => e.stopPropagation()}>
                <input type="checkbox" class="trigger-radiobutton custom-control-input" id={`chechbox_status_${nodeId}`}
                  checked={nodeData.is_enabled} onChange={onChangeStatusTrigger} />
                <label class="custom-control-label" for={`chechbox_status_${nodeId}`} >
                  <span className="d-block">
                    {nodeData.is_enabled ? "Activo" : "Desactivado"}
                  </span>
                </label>
              </div>
            </div>
          )}

          <Factory.TargetPoints action={action} />
        </div>

        {(warnings.length > 0 || errors.length > 0) && (
          <div className="dropright warnings-errors">

            <div className="text-center dropdown-toggle dropdown-icons" data-toggle="dropdown" aria-haspopup="true" data-offset="-50,-330">

              {errors.length > 0 ? (
                <div className="errors">
                  <i class="fa-solid fa-circle-xmark text-danger"></i>
                </div>
              ) : (
                <div className="warnings">
                  <i class="fa-solid fa-triangle-exclamation text-warning"></i>
                </div>
              )}

            </div>

            <Factory.DropdownErrors
              warnings={warnings}
              errors={errors}
              actionUiSettings={action.uiSettings}
            />

          </div>
        )}

      </div>
    )}
  </div>
);

export default NodeView;