import React, { useEffect, useState } from "react";
import { useQuery } from "seed/gql";
import View from "components/misc/templates/Flows.view";


function Flows({
  Flow,
  manualFlows,
  flowsQuery = "",
  style = {},
  arrowsStyle = {},
  arrowContainerStyle = {},
  scrollAmount = 500
}) {

  const [activeFlow, setActiveFlow] = useState(null);

  const reqFlows = useQuery(`{
    flows {
      name
      createdAt
      description
      isTemplate
      templateName
      templateSettings
      templateCategories {
        name
        description
        uiSettings
      }
    }
  }`, flowsQuery)

  useEffect(() => {
    reqFlows.refetch();
    setActiveFlow(null);
  }, [flowsQuery])

  const onSelectFlow = (flowId) => {
    setActiveFlow(flowId)
  }

  const isTemplateFlowsLoading = reqFlows.loading;
  let newFlows = [];
  if (!manualFlows) {
    const { flows = [] } = reqFlows.data;
    newFlows = [...flows]
  } else {
    newFlows = [...manualFlows]
  }

  if (newFlows != null && Array.isArray(newFlows))
    newFlows.sort((a, b) => a?.templateSettings?.relevance - b?.templateSettings?.relevance);

  return (
    <View
      Flow={Flow}
      flows={newFlows}
      activeFlow={activeFlow}
      scrollAmount={scrollAmount}
      style={style}
      arrowsStyle={arrowsStyle}
      arrowContainerStyle={arrowContainerStyle}
      isTemplateFlowsLoading={isTemplateFlowsLoading}
      onSelectFlow={onSelectFlow}
    />
  );
}

Flows.propTypes = {};

export default Flows;