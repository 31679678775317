
import React, { useState, useEffect, useMemo } from 'react';
import { usePost } from "seed/api";
import { useQuery, useDetail } from "seed/gql";
import ExecModeCards from 'components/flow/exec_mode/panels/Data.lib.cards';
import ExecModeTable from 'components/flow/exec_mode/panels/Data.lib.table';
import ExecModeList from 'components/flow/exec_mode/panels/Data.lib.list';
import { formatNumber } from "components/tables/util/format";

const ExecModeData = ({ flow, execModeSetting }) => {

	const pageSize = 10;
  const [activeCollectionId, setActiveCollectionId] = useState(null);
  const [pageNum, setPageNum] = useState(parseInt(sessionStorage.getItem("collection_page")) || 1);
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
  const availableCollections = useMemo(() => execModeSetting.tables??[], [execModeSetting.tables]);
  const activeSettings = useMemo(() => availableCollections.find(t => t.id == activeCollectionId) || {}, 
		[availableCollections, activeCollectionId]);
  
	const collectionFilter = columns.filter(column => column.type === "collection")
		.map(column => `(id = ${column?.structure?.collection_id})`)
		.join(" OR ");

	const qCollectionData = useQuery(`{
		collectionColumns: collections {
			id
			name
			schema
			initials
			folios
			collectionDataes {
				id
				data
				folio
			}
		}
	}`, collectionFilter.length > 0 ? `(${collectionFilter})` : "null");

  const qCollection = useDetail(`{
		collection {
			id
			schema
			name
			initials
		}
	}`, activeCollectionId);

  const qCollections = useQuery(`{
		collections {
			id
			name
			initials
		}
	}`, "flowId=" + flow.id, {
		onCompleted: (data) => {

			const collections = data.collections.filter(c => availableCollections.length === 0 ||
				availableCollections.map(c => c.id).includes(c.id));

			if (collections.length > 0 && !activeCollectionId) {

				let defCollectionId = collections[0].id

				for (let collection of collections)
					if (collection.id == sessionStorage.getItem("def_collection_" + flow.id))
						defCollectionId = collection.id

				setActiveCollectionId(defCollectionId)

			}

		}
	});

  const [callGetData, reqGetData] = usePost("/collections/get_data");

  const refreshData = () => callGetData({
		collection_id: activeCollectionId,
		page_size: pageSize,
		page_number: pageNum,
	});

  const onPageChange = (page) => {
		if (page < 1) page = 1;
		if (page > totalPages) page = totalPages;
		sessionStorage.setItem("collection_page", page)
		sessionStorage.setItem("collection_total_pages", totalPages)
		setPageNum(page);
	}

	const onClickTab = (collectionId) => {
		sessionStorage.setItem("def_collection_" + flow.id, collectionId)
		sessionStorage.removeItem("collection_search")
		sessionStorage.removeItem("collection_query")
		sessionStorage.removeItem("collection_filters")
		sessionStorage.removeItem("collection_page")
		sessionStorage.removeItem("collection_total_pages")
		setActiveCollectionId(collectionId);
	}

	const { collectionColumns = [] } = qCollectionData.data || {};
	const collectionColumnsAux = collectionColumns.map(c => ({
		...c,
		collectionDataes: c.collectionDataes.map(d => ({
			...d,
			_folio: `${c.initials}-${formatNumber(d.folio)}`
		}))
	}));

  const { collection } = qCollection.data || {};
  const { collections: auxCollections = [] } = qCollections.data || {};
  const collections = auxCollections.filter(c => availableCollections.length === 0 || 
		availableCollections.map(c => c.id).includes(c.id));

  const collectionDataes = (reqGetData.data || {}).data;
  const totalPages = (reqGetData.data || {}).total_pages || 0;

  useEffect(() => {
		setRows([]);
		qCollection.refetch();
		refreshData();
	}, [pageNum, activeCollectionId]);

  useEffect(() => {

		if(!collection) return;

		const sortedData = Object.values(collectionDataes || []);
		sortedData.sort((a, b) => a.folio - b.folio);

		const data = sortedData.map(d => ({
			data: function () {
				try { return JSON.parse(d.data) ?? {} }
				catch (e) { return (d.data) ?? {} }
			}(),
		}));

		setRows(data);

	}, [collectionDataes, collection]);

  useEffect(() => {
		if(collection && collection.schema) {
			let schema = {};
			try { schema = JSON.parse(collection.schema) ?? {} }
			catch (e) { schema = (collection.schema) ?? {} }
      schema = Object.assign({}, schema);
      schema.structure = schema.structure.filter(c => activeSettings?.columns?.includes(c.name));
			setColumns(schema.structure || []);
		}
	}, [collection, activeSettings]);

	return <div 
		style={{ 
			height: "100%",
			overflowY: "auto",
		}}
	>

		<div className="w-100 h-100" style={{ paddingTop: "0.3rem" }}>
			<div className="w-100 p-4">

				<h3 className='mb-2 p-0 m-0'>
					Datos - {collection?.name}
				</h3>

				{
					availableCollections.length > 1 &&
						<div id="tables">
							<div className="w-100" id="tables-header">
								<div id="collection-tabs" className="scrollbar-narrow d-flex w-100 align-items-baseline pt-3"
									style={{ overflowX: "auto" }}>
									{(collections ?? []).map((collection, index) => (
										<a
											key={index}
											style={{
												maxWidth: "16rem",
											}}
											className={"btn collection-tab d-flex align-items-center mr-2 px-3 py-0 " +
												(activeCollectionId == collection.id ? "active" : "")}
										>
											<span 
												className="text-truncate py-2" 
												onClick={() => onClickTab(collection.id)}
											>
												{collection.name}
											</span>
										</a>
									))}
								</div>
							</div>
						</div>
				}

				{
					activeSettings.view == "TABLE" &&
						<ExecModeTable
							collections={collectionColumnsAux}
							values={rows}
							structure={columns}
							pageNum={pageNum}
							totalPages={totalPages}
							onClickTab={onClickTab}
							onPageChange={onPageChange}
						/>
				}

				{
					activeSettings.view == "LIST" &&
						<ExecModeList
							collections={collectionColumnsAux}
							values={rows}
							structure={columns}
							pageNum={pageNum}
							totalPages={totalPages}
							onClickTab={onClickTab}
							onPageChange={onPageChange}
						/>
				}

				{
					activeSettings.view == "CARDS" &&
						<ExecModeCards
							collections={collectionColumnsAux}
							values={rows}
							structure={columns}
							pageNum={pageNum}
							totalPages={totalPages}
							onClickTab={onClickTab}
							onPageChange={onPageChange}
						/>
				}

				{
					activeSettings.view != "TABLE" &&
						<div className="w-100 px-3 m-0">
							<nav class="mt-2 scrollbar-narrow" style={{ overflowX: "auto" }}>
								<ul class="pagination d-flex align-items-center mb-1">

									<li
										onClick={() => onPageChange(pageNum - 1)}
										class="page-item"
										style={{ display: pageNum > 1 ? "block" : "none" }}
									>
										<a class="page-link" aria-label="Prev">
											<span aria-hidden="true">«</span><span class="sr-only">Prev</span>
										</a>
									</li>

									{
										totalPages > 1 ?
										Array(totalPages).fill(0).map((_, idx) =>
											<li
												key={idx}
												onClick={() => onPageChange(idx + 1)}
												class={"page-item m-0 mr-2 " + (idx == pageNum - 1 ? "page-active" : "")}
											>
												<a
													class={`btn btn-sm ${idx == pageNum - 1 ? "btn-primary" : "btn-soft-secondary"} px-3`}
													role="button"
												>
													{idx + 1}
												</a>
											</li>
										) : null
									}

									<li
										onClick={() => onPageChange(pageNum + 1)}
										class="page-item"
										style={{ display: pageNum <= totalPages - 1 ? "block" : "none" }}
									>
										<a class="page-link" aria-label="Sig">
											<span aria-hidden="true">»</span><span class="sr-only">Sig</span>
										</a>
									</li>

								</ul>
							</nav>
						</div>
				}

			</div>
		</div>

	</div>

}

export default ExecModeData;