import React from "react";
import UseCaseItem from "components/misc/use_cases/UseCases.sub.Item.view";
import UseCaseCategory from "components/misc/use_cases/UseCases.sub.Category.view";
import { LANDING_URL } from "settings"
import { Loading } from "seed/helpers";
import Categories from "components/misc/templates/Categories";
import Flows from "components/misc/templates/Flows";
import "styles/css/UseCases.css";


const UseCasesView = ({
  flows,
  selectedProfile,
  templateCategories,
  isTemplateProfilesLoading,
  isFlowTemplatesLoading,
  onChangeProfile
}) => (
  <div id="use-cases">
    <div id="use-cases-container" class="container w-100 h-100 p-3">

      <a class="d-flex justify-content-center" href={LANDING_URL}>
        <img class="z-index-2 workix-logo"
          src="/theme/svg/logos/logo-white.svg" alt="Logo"/>
      </a>

      <div className="d-flex flex-column align-items-center justify-content-center mt-5 animate__animated animate__fadeInUp w-100">

        <div id="use-cases-title" class="mb-3 text-center">
          <h1 className="text-white">Soluciones</h1>
          <span className="text-wrap">
            Conoce algunos ejemplos que Workix tiene para ti
          </span>
        </div>

        {/* Template Categories */}
        {isTemplateProfilesLoading ? <Loading /> : (
          <Categories
            Category={(props) => <UseCaseCategory {...props} onChangeProfile={onChangeProfile} />}
            manualCategories={templateCategories}
            initialActiveCategory={selectedProfile ? selectedProfile.id : null}
            style={{ width: (templateCategories.length * 7) + "%", marginBottom: "1.75rem" }}
            arrowsStyle={{
              color: "white",
              border: "2px solid white",
              fontSize: "1em"
            }}
            arrowContainerStyle={{ width: "100%" }}
            scrollAmount={400}
          />
        )}

        {/* Template Flows */}
        {isFlowTemplatesLoading ? <Loading /> : (
          <Flows
            Flow={(props) => <UseCaseItem {...props} profile={selectedProfile} />}
            manualFlows={flows}
            scrollAmount={600}
            style={{ width: "100%" }}
            arrowContainerStyle={{ width: "100%" }}
          />
        )}

      </div>

    </div>
  </div>
);

export default UseCasesView;