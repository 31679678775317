import React from "react";
import ExecutionsList from "components/start/executions/ExecutionsList";
import LineChart from "components/misc/charts/LineChart";
import BarChart from "components/misc/charts/BarChart";


const ExecutionsView = ({
  flow,
  showCharts,
  showList,
  listPosition,
  lineChartExecutions,
  barChartExecutions,
  styles
}) => (
  <div
    className="px-2"
    style={{
      width: "100%",
      height: "calc(100% - 110px)",
      paddingTop: "0.3rem",
      ...styles
    }}
  >
    <div className="w-100 p-4">

      <div className="row">

        {
          showList && listPosition == "top" &&
            <div className="col-lg-12 mb-4" >
              <h3 className="mb-3">Ejecuciones</h3>
              <ExecutionsList flow={flow} placeholder="No hay acciones" />
            </div>
        }

        {
          showList && listPosition == "left" &&
            <div className="col-lg-5 mb-4" >
              <h5 className="mb-3">Ejecuciones</h5>
              <ExecutionsList flow={flow} placeholder="No hay acciones" />
            </div>
        }

        {
          showCharts &&
            <div className={`col-lg-${showList && listPosition === "left" ? "7" : "12"}`} >
              <div className="mb-3">
                <LineChart data={lineChartExecutions.data} options={lineChartExecutions.options} height={350} />
              </div>
              <div className="">
                <BarChart data={barChartExecutions.data} options={barChartExecutions.options} height={350} />
              </div>
            </div>
        }

      </div>

    </div>
  </div>
);

export default ExecutionsView;