import React from "react";
import { Link } from "react-router-dom";
import ScrollArrow from "components/util/helpers/ScrollArrow";


const FlowsView = ({
  userId,
  folder,
  recentFlows,
  flowCount,
  setSelectedFolder,
  onDeleteFlow,
}) => (
  <div id="start-flows">
    <div id="start-flows-container">
      <div id="start-flows-header" className={"d-flex align-items-center ml-1 justify-content-between"}>

        <h2 title="Procesos" className="mb-0" style={{ color: "white" }}>
          <i class="fas fa-shapes mr-3"></i>
          Procesos recientes
          &nbsp;<span className="text-primary">{folder ? "(" + folder.name + ")" : ""}</span>
        </h2>
        <div>
          <Link to={`${folder?.id ?? 0}/create_flow`}
            className={"btn btn-primary ml-3 mr-2"}
            style={{ visibility: recentFlows.length > 0 ? "visible" : "hidden" }}>
            <i class="fas fa-plus mr-2"></i>Crear proceso
          </Link>
        </div>
      </div>

      {recentFlows.length > 0 ? (
        <div id="flows" class="w-100">
          <div id="flows-container" class="d-flex flex-wrap justify-content-between">
            <ScrollArrow scrollAmount={600} itemsContainerStyle={{ width: "100%" }} align="left">
              {recentFlows.map((flow) => (
                <div key={flow.id} className={"flow col-12 px-0 col-md-" + (recentFlows.length > 1 ? "6" : "12")}>
                  <div className="card card-hover-shadow pb-0 px-0 mr-2">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <div>
                        <span class="badge bg-soft-success text-success p-2 ml-3">Activo</span>
                        {function () {
                          const participants = flow?.participants ?? [];
                          const participant = participants.find((participant) => participant?.user?.id == userId);
                          const isParticipant = participant != null && participant != undefined;

                          if (isParticipant) {
                            return (<span class="badge bg-soft-primary text-primary p-2 ml-2">Compartido</span>);
                          }

                        }()}
                      </div>

                      {flow.user.id == userId && (
                        <div class="btn-group dropup flow-settings">
                          <button type="button" class="btn btn-lighter text-center dropdown-toggle py-3 px-4 m-2 rounded-circle"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            onClick={e => e.preventDefault()}
                          >
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu pr-1 flow-settings-content">
                            <Link to={`/${flow.id}/share`} class="dropdown-item">
                              <i class="fa-solid fa-share-from-square dropdown-item-icon mr-1"></i> Compartir
                            </Link>
                            <Link to={`/${flow.id}/edit_flow`} class="dropdown-item">
                              <i class="fas fa-pen dropdown-item-icon mr-1"></i> Editar proceso
                            </Link>
                            <Link to={`/presentation/${flow.id}`} class="dropdown-item">
                              <i class="fa-solid fa-display dropdown-item-icon mr-1"></i> Modo presentación
                            </Link>

                            <hr className="my-3" />

                            <a class="dropdown-item" style={{ cursor: "pointer" }}
                              onClick={e => { e.preventDefault(); onDeleteFlow(flow.id); }}>
                              <i class="tio-delete dropdown-item-icon mr-1"></i> Eliminar proceso
                            </a>

                          </div>
                        </div>
                      )}
                    </div>

                    <div to={`/flow/${flow.id}/flow`} class="card-body text-center flow-contents mt-n2">
                      <div class="avatar avatar-xl avatar-border-lg mb-1">
                        <img src="/resources/images/icon-45.svg" alt="Flow" style={{ width: "3.7rem" }} />
                      </div>

                      <h3 className={"flow-name mt-0 mb-0"}>
                        <div class="text-dark h4 text-truncate w-100 d-inline-block mb-0">
                          {flow.name}
                        </div>
                      </h3>

                      <div class="flow-folder w-100">
                        <a className="text-dark text-truncate w-100 d-inline-block" href="#"
                          onClick={() => setSelectedFolder(flow.folder?.id)}>
                          <i class="far fa-folder mr-1" style={{ display: flow.folder ? "inline-block" : "none" }}></i>
                          <span>{flow.folder?.name ?? 'Principal'}</span>
                        </a>
                      </div>

                    </div>

                    <div class="card-footer">
                      <div class="py-1 w-100 px-3">
                        <Link to={`/flow/${flow.id}/flow`}
                          class="btn btn-primary w-100">
                          <i class="fas fa-play mr-2"></i>
                          Ir a proceso
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              )).concat(
                flowCount > 4 && recentFlows.length > 4 ?
                  [

                    <div key="more" className="flow col-4 px-0 col-md-3" style={{ minHeight: "20rem" }}>
                      <div className="card card-hover-shadow pb-0 px-0 mr-2 h-100">
                        <div className="d-flex justify-content-between align-items-center mb-0">
                        </div>

                        <Link to={`/settings/flows`} class="card-body text-center flow-contents">
                          <h3 className={"flow-name mt-0 mb-0"}>
                            <div class="text-dark h4 text-truncate w-100 d-inline-block mb-0 mt-10">
                              Ver todos
                            </div>
                          </h3>

                        </Link>

                      </div>
                    </div>
                  ]

                  : [])

              }

            </ScrollArrow>
          </div>

        </div>
      ) : (
        <div id="no-flows" class="card w-100 shadow-none mt-2">
          <div class="card-body">
            <div className="d-flex flex-column justify-content-center py-9">
              <span id="no-flows-title" className="text-center mb-4">No tienes procesos registrados</span>
              <div className="d-flex justify-content-center">
                <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-primary w-25" href="#">
                  <i class="fas fa-plus mr-2"></i>Crear proceso
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>

  </div>
)

export default FlowsView;