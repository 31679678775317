
/* eslint-disable max-len */
import React from "react";
import { Link, NavLink, Switch, Redirect } from "react-router-dom";
import { Tab } from "components/flow/Controller.sub.view";
import { ModalRoute, Route } from "seed/helpers"
import { Modal } from "seed/helpers";
import Board from "components/flow/board/Board";
import Tables from "components/tables/Tables";
import Toolbox from "components/flow/toolbox/Toolbox";
import CreateNodeForm from "components/flow/board/action_form/CreateNodeForm";
import EditNodeForm from "components/flow/board/action_form/EditNodeForm";
import EditFormAnnotation from "components/flow/board/action_form/EditFormAnnotation";
import FormSetFlow from "components/start/flows/FormSetFlow";
import RPADesigner from "components/misc/apps/rpa/designer/RPADesigner";
import Contact from "components/flow/forms/contact_us/Contact";
import FormSavePage from "components/flow/forms/pages/FormSavePage";
import FormSetPage from "components/flow/forms/pages/FormSetPage";
import Executions from "components/start/executions/Executions";
import SetupApp from "components/flow/toolbox/apps/setup/SetupApp";
import Collaboration from "components/flow/forms/collaboration/Collaboration";
import SuggestionsReport from "components/flow/suggestions/SuggestionsReport";
import Execution from "components/flow/forms/executions/Execution";
import ExecutionsList from "components/start/executions/ExecutionsList";
import ExecMode from "components/flow/exec_mode/ExecMode";
import Settings from "components/flow/exec_mode/Settings";
import { postMessage } from "components/util/win";
import HelpMessage from 'components/util/helpers/HelpMessage';
import CopyClipboardButton from "components/util/helpers/CopyClipboardButton";
import Suggestions from "components/flow/suggestions/Suggestions"
import { StyleTag } from "seed/helpers";
import "styles/css/Flow.css";


const ControllerView = ({
  flow,
  nodes,
  edges,
  user,
  selectedNodes,
  activePage,
  alertStatus,
  reactFlowWrapper,
  actualExecutionId,
  execModeRef,
  isFlowBeingSaved,
  isDeveloper,
  isAnonymous,
  isContactUsShown,
  isDesignerShow,
  isRPADesigner,
  isExecutionDetailsShown,
  isPendingActivity,
  isExecMode,
  setIsContactUsShown,
  setReactFlowInstance,
  setActivePage,
  setIsDesignerShow,
  setIsExecutionDetailsShown,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickNode,
  onCreateNode,
  onEditNode,
  onCreateAnnotation,
  onEditAnnotation,
  onCloseModalRoute,
  onClickAlertBanner,
  onClickAlertBell,
  onClickExport,
  onClickImport,
  onClickAnnotation,
  onClickUndo,
  onClickRedo,
  onDeleteSelectedNodes,
  onChangeExecMode,
  onExecuteNode,
  reloadFlow,
  refetchExecutions
}) => (
  <div className="card p-0" id="flow">
    <div class="container-fluid p-0 h-100">

      <div class="d-flex h-100">

        <div id="toolbox">

          {
            <div class="d-flex align-items-center pl-2" id="toolbox-header">

              <a href={!isAnonymous ? "/app" : "/use_cases"}
                className="back-button-flow d-flex align-items-center justify-content-center"
                title="Regresar">
                <i class="fas fa-arrow-left d-block"></i>
              </a>

              <div class="w-auto">
                <div className="logo"></div>
              </div>

              <div class="ml-0" id="toolbox-title">

                <div class="dropdown navbar-dropdown-account-wrapper rounded pr-2">

                  <div class="dropdown-toggle ml-2" data-toggle="dropdown" aria-expanded="false">
                    <span class="d-flex align-items-center h5 text-hover-primary mb-0 text-truncate">
                      <span className="d-inline-block text-truncate" id="toolbox-flow-name">
                        {flow.name}
                      </span>
                      {!isAnonymous ? <small class="ml-1"><i class="fas fa-chevron-down"></i></small> : null}
                    </span>
                    <span class="d-block font-size-sm text-body">
                      {!isAnonymous ? `Plan ${(user?.license?.name ?? "básico").toLowerCase()}` : "Demo"}
                    </span>
                  </div>

                  <div class="dropdown-menu pr-1" aria-labelledby="dropdownFlowSettings">
                    {!isAnonymous ? <>
                      <Link to="/flow/edit_flow" class="dropdown-item">
                        <i class="fas fa-pen dropdown-item-icon mr-1"></i>Cambiar nombre
                      </Link>
                      <a href={`/app/settings/billing`} class="dropdown-item">
                        <i class="fas fa-money-bill dropdown-item-icon mr-1"></i>Ver plan
                      </a>
                    </> : null}
                  </div>
                </div>

              </div>
            </div>
          }

          <Toolbox
            flowId={flow.id}
            nodes={nodes.filter((item) => item.type == "node")}
            isDisabled={isExecMode}
            isAnonymous={isAnonymous}
            onClickNode={onClickNode}
          />

          {/* {
            isExecMode 
              ? <ExecutionsList
                  flow={flow}
                  userId={user.id}
                  placeholder="No hay actividad reciente"
                />
              :
          } */}

        </div>

        <div id="workspace" class={"w-100 mr-4 " + (!isRPADesigner ? "pl-5" : "pl-3")}>

          {!isRPADesigner && (
            <div className="d-flex justify-content-between" id="workspace-header">

              <div id="workspace-switch" className="rounded px-2">
                <div class="custom-control custom-switch my-auto accent">
                  <input
                    id="execMode"
                    name="execMode"
                    type="checkbox"
                    class="custom-control-input accent"
                    checked={!isExecMode}
                    onChange={() => onChangeExecMode(!isExecMode)}
                  />
                  <label class="custom-control-label input-label" for="execMode">
                    Modo edición
                  </label>
                </div>
              </div>

              {!isExecMode && (
                <div id="flow-tab">
                  <Tab
                    flow={flow}
                    route={"/flow"}
                    title={"Proceso"}
                    caption={"Diseña tu proceso con las acciones que necesites"}
                    width={"7.7rem"}
                    onClick={refetchExecutions}
                  />

                  <Tab
                    flow={flow}
                    route={"/data"}
                    title={"Datos"}
                    caption={"Almacena información de tu proceso como datos de clientes, empleados o solicitudes"}
                    width={"7.7rem"}
                  />
                </div>
              )}

              <div id="workspace-actions">

                <span className="mr-1">

                  {!isAnonymous && (
                    <>
                      <HelpMessage message="Comparte tu proceso con otras personas de forma rápida, permitiendo a otros colaborar">
                        <Link to="/flow/share" className="btn btn-sm btn-lighter mr-1 share-button">
                          <i class="fas fa-share mr-1"></i> Compartir
                        </Link>
                      </HelpMessage>

                      <a className="btn btn-sm btn-lighter ml-1" aria-haspopup="true"
                        id="dropdownGeneralSettings" data-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-ellipsis-vertical px-1"></i>
                      </a>
                    </>
                  )}

                  <div class="dropdown-menu" aria-labelledby="dropdownGeneralSettings">

                    <HelpMessage message="Convierte tu proceso en un sitio web moderno y compártelo públicamente mediante una url">
                      <a href={`/p/${flow.token}`} className="dropdown-item">
                        <i class="fas fa-chalkboard-teacher dropdown-item-icon mr-1"></i> Modo presentación
                      </a>
                    </HelpMessage>

                    <Link to="/flow/activity" className="dropdown-item">
                      <i class="fa-solid fa-chart-line dropdown-item-icon mr-1"></i> Actividad
                    </Link>

                    {isDeveloper && (
                      <>
                        <hr className="my-2" />

                        <button
                          className="dropdown-item"
                          onClick={() => {
                            postMessage({ type: "open_capture", flow_id: flow.id, page_id: activePage });
                          }}>
                          <i class="fa-solid fa-mouse-pointer dropdown-item-icon mr-1"></i> RPA Debug
                        </button>

                        <button className="dropdown-item" onClick={onClickExport} >
                          <i class="fa-solid fa-download dropdown-item-icon mr-1"></i> Exportar datos
                        </button>

                        <button className="dropdown-item" onClick={onClickImport}>
                          <i class="fa-solid fa-upload dropdown-item-icon mr-1"></i> Importar datos
                        </button>
                      </>
                    )}

                    <hr className="my-2" />

                    <Link to="/flow/edit_flow" class="dropdown-item">
                      <i class="fas fa-pen dropdown-item-icon mr-1"></i>Editar proceso
                    </Link>

                    <a href={`/app/settings`} class="dropdown-item">
                      <i class="fas fa-cog dropdown-item-icon mr-1"></i>Ajustes
                    </a>

                    <hr className="my-2" />

                    <a href="/logout" className="dropdown-item">
                      <i class="fa-solid fa-sign-out-alt dropdown-item-icon mr-1"></i> Cerrar sesión
                    </a>

                  </div>
                </span>

                {isAnonymous && (
                  <button className="btn btn-primary btn-sm" onClick={() => setIsContactUsShown(true)}>
                    <i class="fa-solid fa-comment mr-1"></i> Contáctanos
                  </button>
                )}

              </div>
            </div>
          )}

          {isExecMode ? (
            <ExecMode
              ref={execModeRef}
              flow={flow}
              onExecuteNode={onExecuteNode}
            />
          ) : (
            <Switch>
              <Route path="/flow" render={(props) =>
                <Board
                  {...props}
                  nodes={nodes}
                  edges={edges}
                  flowId={flow.id}
                  activePage={activePage}
                  selectedNodes={selectedNodes}
                  isFlowBeingSaved={isFlowBeingSaved}
                  isRPADesigner={isRPADesigner}
                  isAnonymous={isAnonymous}
                  reactFlowWrapper={reactFlowWrapper}
                  setActivePage={setActivePage}
                  setReactFlowInstance={setReactFlowInstance}
                  onDropNode={onDropNode}
                  onDragNode={onDragNode}
                  onConnectEdge={onConnectEdge}
                  onUpdateEdge={onUpdateEdge}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onClickNode={onClickNode}
                  onClickAnnotation={onClickAnnotation}
                  onDeleteSelectedNodes={onDeleteSelectedNodes}
                  onClickUndo={onClickUndo}
                  onClickRedo={onClickRedo}
                />
              } />
              <Route path="/data" render={() =>
                <Tables
                  flowId={flow?.id}
                />
              } />
              <Redirect to={sessionStorage.getItem("def_tab_" + flow.id) ?? "/flow"} />
            </Switch>
          )}
        </div>

        {
          !isExecMode &&
          <div id="assistant">
            <Suggestions flowId={flow.id} />
          </div>
        }

      </div>
    </div>

    {isDesignerShow && (
      <Modal
        width={800}
        height={600}
        animation={""}
        onClose={() => setIsDesignerShow(false)}
        component={() =>
          <RPADesigner />
        }
      />
    )}

    {isContactUsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsContactUsShown(false)}
        component={Contact}
      />
    )}

    {isExecutionDetailsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsExecutionDetailsShown(false)}
        component={() => <Execution executionId={actualExecutionId} />}
      />
    )}

    <ModalRoute
      width="1200"
      height="800"
      path="/flow/suggestions/report"
      isDeveloper={isDeveloper}
      component={SuggestionsReport}
    />

    <ModalRoute
      width="500"
      height="450"
      path="/flow/edit_flow"
      component={() => <FormSetFlow match={{ params: { "flowId": flow.id } }} />}
    />

    <ModalRoute
      width={window.innerWidth - 150}
      height={window.innerHeight - 150}
      path="/flow/activity"
      component={() =>
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h3 className="card-title">Actividad</h3>
          </div>
          <div className="card-body">
            <Executions flow={flow} />
          </div>
        </div>
      }
    />

    <ModalRoute
      width="450"
      height="500"
      path="/flow/pending_activity"
      component={() =>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Acciones pendientes</h3>
          </div>
          <div className="card-body">
            <Executions flow={flow}
              status={"AWAITING"}
              placeholder="No hay acciones pendientes"
            />
          </div>
        </div>
      }
    />

    <ModalRoute
      width="400"
      height="400"
      path="/flow/create_page"
      onClose={onCloseModalRoute}
      component={() => <FormSavePage match={{ params: { "flowId": flow.id } }} />}
    />

    <ModalRoute
      width="420"
      height="400"
      path="/flow/edit_page/:pageId"
      onClose={onCloseModalRoute}
      component={(props) => <FormSetPage match={{ params: { ...props.match?.params, "flowId": flow.id } }} />}
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/create/:actionId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <CreateNodeForm
          match={props.match}
          flowId={flow.id}
          onSubmit={onCreateNode}
        />
      }
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/edit/:nodeId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <EditNodeForm
          match={props.match}
          flowId={flow.id}
          onSubmit={onEditNode}
        />
      }
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/create_annotation/:actionId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <CreateNodeForm
          match={props.match}
          flowId={flow.id}
          onSubmit={onCreateAnnotation}
        />
      }
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/edit_annotation/:annotationId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <EditFormAnnotation
          match={props.match}
          flowId={flow.id}
          onSubmit={onEditAnnotation}
        />
      }
    />

    <ModalRoute
      width={600}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/setup/:appId"
      component={(props) =>
        <SetupApp
          match={props.match}
          flowId={flow.id} />}
    />

    <ModalRoute
      width={650}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/share"
      component={() => <Collaboration flowId={flow.id} />}
    />

    <ModalRoute
      width={750}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/website/:url"
      component={({ match }) => <div className="card w-100 h-100">
        <div className="w-100 text-center py-2">Vista previa:&nbsp;
          <a href={decodeURIComponent(match.params?.url)} target="_blank" rel="noreferrer">{decodeURIComponent(match.params?.url)}</a>
          <CopyClipboardButton link={decodeURIComponent(match.params?.url)} />
        </div>
        <iframe className="w-100 h-100"
          src={decodeURIComponent(match.params?.url)}
          referrerpolicy="same-origin" title="website" />
      </div>}
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/exec_mode/settings"
      onClose={onCloseModalRoute}
      component={(props) =>
        <Settings
          flow={flow}
          execModeRef={execModeRef}
        />
      }
    />

  </div>
);

export default ControllerView;