
import React from "react";
import { Formik, Form } from "formik";
import * as Fields from "components/schema_form/fields";

const SettingsView = ({
  formRef,
  flow,
  collections = [],
  execModeDefaults = [],
  execModeSettings = [],
  activeTab,
  getPanelOptions,
  getColumnOptions,
  onCreateTable,
  onChangeSelectedTable,
  onAddSection,
  onChangeTab,
  onDelete,
  onSubmit,
  onReset
}) => (
  <div className="card p-3">

    <div className="card-title d-flex justify-content-between mb-1">
      <h2>Personalizar</h2>
      <button 
        type="button"
        class="btn btn-link"
        onClick={onReset}
      >
        Restablecer default
      </button>
    </div>

    <div className="card-body p-0">
      <Formik
        innerRef={formRef}
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {({ values, setValues, setFieldValue }) => (
          <Form className="m-0 p-0">

            {/* Tabs */}
            <div className="d-flex mb-3">

              {
                execModeSettings.map((execModeSetting, index) => (
                  <div
                    key={index}
                    className={`btn d-flex justify-content-center px-2 mr-2 exec-mode-tab ${activeTab === index ? "active" : ""}`}
                    style={{ width: "11rem" }}
                    onMouseEnter={() => setFieldValue("hoverTab", index)}
                    onMouseLeave={() => setFieldValue("hoverTab", null)}
                  >
                    <span 
                      className="text-truncate" 
                      onClick={() => onChangeTab(values, index)}
                    >
                      {execModeSetting.title ?? "Nueva sección"}
                    </span>
                    {
                      values.hoverTab === index &&
                        <span>
                          <i className="fas fa-trash ml-3" onClick={() => onDelete(index)}></i>
                        </span>
                    }
                  </div>
                ))
              }

              <button 
                type="button"
                className={`btn dropdown-header text-center text-truncate px-2 mr-2 exec-mode-tab`}
                style={{ width: "10rem" }}
                onClick={() => onAddSection(values)}
              >
                <i className="fas fa-plus"></i> Agregar
              </button>

            </div>

            <h5>
              {execModeSettings[activeTab]?.title ?? "Nueva sección"}
            </h5>

            {
              function(){

                let currentDefaults = execModeDefaults.find(d => d.root.id.toString() == values.reference);
                if(!currentDefaults) currentDefaults = {};
              
                return <>

                  {/* Fields */}
                  <div className="">
                    <ul className={"step step-form step-eicon-xs mb-0"}>
                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.TextField
                            required={true}
                            label={"Título"}
                            name={"title"}
                            values={values}
                            disabled={!values.is_manual_title}
                            setFieldValue={setFieldValue}
                          />
                          <button
                            type="button"
                            class="btn btn-link"
                            onClick={() => {
                              setFieldValue("is_manual_title", !values.is_manual_title)
                              if(values.is_manual_title) setFieldValue("title", currentDefaults.title)
                            }}
                          >
                            {
                              !values.is_manual_title 
                                ? <i class="fas fa-lock"></i> 
                                : <i class="fas fa-lock-open"></i>
                            }
                          </button>
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.SelectField
                            required={true}
                            label={"Referencia"}
                            name={"reference"}
                            setFieldValue={setFieldValue}
                            value={values.reference}
                            values={values}
                            disabled={!values.is_manual_reference}
                            helpMessage={"Referencia del flujo a ejecutar"}
                            options={
                              execModeDefaults.map(s => ({ label: s.title, value: s.root.id.toString() }))
                            }
                          />
                          <button
                            type="button"
                            class="btn btn-link"
                            onClick={() => {
                              setFieldValue("is_manual_reference", !values.is_manual_reference)
                              if(values.is_manual_reference) setFieldValue("reference", currentDefaults.root?.id?.toString())
                            }}
                          >
                            {
                              !values.is_manual_reference 
                                ? <i class="fas fa-lock"></i> 
                                : <i class="fas fa-lock-open"></i>
                            }
                          </button>
                        </div>
                        
                      </FieldWrapper>
                    </ul>
                  </div>

                  {/* Panels */}
                  <div 
                    className="row px-4 pt-0 mt-0"
                    style={{
                      overflowX: "hidden",
                    }}
                  >
                    <div 
                      className={function(){
                        if(values.right_panel == "EMPTY") return "col-md-8";
                        if(values.left_panel == "EMPTY") return "col-md-4";
                        return "col-md-6";
                      }()}
                    >
                      <div class="card">
                        <div class="card-header d-block text-center">
                          <div class="mb-2">
                            <span class="card-title h5" style={{ fontSize: "1.3rem" }}>Panel izquierdo</span>
                          </div>
                          <div className="d-flex justify-content-center px-3">
                            <Fields.SelectField
                              required={true}
                              name={"left_panel"}
                              value={values.left_panel}
                              values={values}
                              setFieldValue={setFieldValue}
                              disabled={!values.is_manual_left_panel}
                              options={getPanelOptions(values, execModeDefaults, false)}
                            />
                            <button
                              type="button"
                              class="btn btn-link"
                              onClick={() => {
                                setFieldValue("is_manual_left_panel", !values.is_manual_left_panel)
                                if(values.is_manual_left_panel) setFieldValue("left_panel", currentDefaults.left_panel)
                              }}
                            >
                              {
                                !values.is_manual_left_panel 
                                  ? <i class="fas fa-lock"></i> 
                                  : <i class="fas fa-lock-open"></i>
                              }
                            </button>
                          </div>
                        </div>
                        <div class="card-body d-flex justify-content-center">
                          <img className="img-fluid" src="/theme/img/left-panel.png" alt="Panel Izquierdo" style={{ height: "200px" }} />
                        </div>
                      </div>
                    </div>
                    <div 
                      className={function(){
                        if(values.left_panel == "EMPTY") return "col-md-8";
                        if(values.right_panel == "EMPTY") return "col-md-4";
                        return "col-md-6";
                      }()}
                    >
                      <div class="card">
                        <div class="card-header d-block text-center">
                          <div class="mb-2">
                            <span class="card-title h5" style={{ fontSize: "1.3rem" }}>Panel derecho</span>
                          </div>
                          <div className="d-flex justify-content-center px-3">
                            <Fields.SelectField
                              required={true}
                              name={"right_panel"}
                              value={values.right_panel}
                              values={values}
                              setFieldValue={setFieldValue}
                              disabled={!values.is_manual_right_panel}
                              options={getPanelOptions(values, execModeDefaults, true)}
                            />
                            <button
                              type="button"
                              class="btn btn-link"
                              onClick={() => {
                                setFieldValue("is_manual_right_panel", !values.is_manual_right_panel)
                                if(values.is_manual_right_panel) setFieldValue("right_panel", currentDefaults.right_panel)
                              }}
                            >
                              {
                                !values.is_manual_right_panel 
                                  ? <i class="fas fa-lock"></i> 
                                  : <i class="fas fa-lock-open"></i>
                              }
                            </button>
                          </div>
                        </div>
                        <div class="card-body d-flex justify-content-center">
                          <img className="img-fluid" src="/theme/img/right-panel.png" alt="Panel Derecho" style={{ height: "200px" }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Table fields */}
                  {
                    (values.left_panel == "TABLE" || values.right_panel == "TABLE") &&
                      <MultipleFieldWrapper
                        title={"Datos"}
                        isDefault={!values.is_manual_tables}
                        onChangeDefaults={() => {
                          console.log(currentDefaults.tables)
                          setFieldValue("is_manual_tables", !values.is_manual_tables)
                          if(values.is_manual_tables) setFieldValue("tables", currentDefaults.tables)
                        }}
                        onAdd={() => 
                          setFieldValue("tables", [...values.tables, {}])
                        }
                        onDelete={(idx) => {
                          setValues({ ...values, tables: values.tables.filter((_, i) => i !== idx) })
                        }}
                      >
                        {
                          (values.tables??[]).map((table, idx) => (
                            <>
                              <FieldWrapper key={idx}>
                                <Fields.VariableSelector
                                  required={true}
                                  label={"Tabla"}
                                  flowId={flow.id}
                                  value={table.name}
                                  values={values}
                                  disabled={!values.is_manual_tables}
                                  name={`tables[${idx}].name`}
                                  acceptTypes={["collection"]}
                                  enableAttributes={false}
                                  showCollectionAddButton={true}
                                  showCollectionCondition={false}
                                  setFieldValue={setFieldValue}
                                  onSaveCollectionSuggestion={onCreateTable}
                                  onChange={(value) => onChangeSelectedTable(value, idx)}
                                />
                              </FieldWrapper>
                              <FieldWrapper>
                                <div className="w-100 mt-1 text-dark">
                                  Columnas <span className='text-danger ml-1'>*</span>
                                </div>
                                {
                                  (table.columns??[]).map((col, colIdx) => (
                                    <div
                                      key={colIdx}
                                      className="d-flex align-items-center w-100"
                                    >
                                      <Fields.SelectField
                                        required={true}
                                        value={col}
                                        values={values}
                                        disabled={!values.is_manual_tables}
                                        name={`tables[${idx}].columns[${colIdx}]`}
                                        options={getColumnOptions(table.table, collections)}
                                        setFieldValue={setFieldValue}
                                      />
                                      <button
                                        type="button"
                                        class="btn btn-link px-1"
                                        disabled={!values.is_manual_tables}
                                        onClick={() => {
                                          let tempVal = {}
                                          let tempCols = table.columns
                                          tempCols.splice(colIdx, 1)
                                          tempVal[`tables[${idx}].columns`] = tempCols
                                          setValues({ ...values, ...tempVal })
                                        }}
                                        style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                                      >
                                        <i class="fas fa-minus-circle"></i>
                                      </button>
                                    </div>
                                  ))
                                }
                                <button
                                  type="button"
                                  class="btn pl-0 mt-1 pt-1"
                                  disabled={!values.is_manual_tables}
                                  onClick={() => setFieldValue(`tables[${idx}].columns`, [...(table.columns??[]), null])}
                                >
                                  <div className="d-flex align-items-center">
                                    <i class="fas fa-plus"></i>&nbsp;Agregar
                                  </div>
                                </button>
                              </FieldWrapper>
                              <FieldWrapper>
                                <Fields.SelectField
                                  required={true}
                                  label={"Visualización"}
                                  name={`tables[${idx}].view`}
                                  value={table.view}
                                  values={values}
                                  disabled={!values.is_manual_tables}
                                  setFieldValue={setFieldValue}
                                  options={[
                                    { label: "Vista de tabla", value: "TABLE" },
                                    { label: "Vista de tarjetas", value: "CARDS" },
                                    { label: "Vista de lista", value: "LIST" }
                                  ]}
                                />
                              </FieldWrapper>
                            </>
                          ))
                        }
                      </MultipleFieldWrapper>
                  }

                </>

              }()
            }

          <button 
            type="submit"
            class="btn btn-primary btn-block ml-2 mt-3"
          >
            Guardar
          </button>

          </Form>
        )}
      </Formik>
    </div>

  </div>
)

const MultipleFieldWrapper = ({ 
  title, 
  children, 
  className = "",
  isDefault = false, 
  onAdd, 
  onDelete,
  onChangeDefaults, 
}) => (
  <div className={"mt-4 " + className}>
    <ul className={"step step-form step-eicon-xs mb-0"}>
      <FieldWrapper className="mb-0">
        <div className="w-100 mt-1">
          <a class="d-flex mb-1 w-100 justify-content-between" data-toggle="collapse" href={"#"}
            style={{ cursor: "default" }}>
              <span class="dropdown-header p-0">
                <span style={{ color: "#777" }}>{title}</span>
                <button type="button" class="btn btn-link px-1" onClick={onChangeDefaults}>
                  {
                    isDefault 
                      ? <i class="fas fa-lock"></i> 
                      : <i class="fas fa-lock-open"></i>
                  }
                </button>
              </span>
          </a>
          <hr class="mt-2 w-100" style={{ border: "none", height: "1.5px", background: "#e7eaf3" }} />
        </div>
        {
          children.map((child, idx) => (
            <div key={idx} className="w-100">
              <ul className={"step step-form step-eicon-xs mb-0 sub w-100"}>
                <div class="d-flex align-items-center justify-content-between mb-1 mt-n1 w-100">
                  <div className={"d-flex"} style={{ width: "92%" }}>
                    <span 
                      className="mr-1"
                      style={{
                        fontSize: "0.72em", 
                        color: "#80858a", 
                        background:"#f0f0f0", 
                        height: "1rem", 
                        padding: "0rem 0.3rem", 
                        borderRadius: "50%"
                      }}
                    >
                      {idx + 1}
                    </span>
                    <div className="w-100">
                      {child}
                    </div>
                    <div>
                      <button 
                        type="button" 
                        class="btn btn-link px-1"
                        onClick={() => onDelete(idx)}
                        disabled={isDefault}
                        style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                      >
                        <i class="fas fa-minus-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          ))
        }
      </FieldWrapper>
      <FieldWrapper>
        <button 
          type="button" 
          class="btn pl-0 mt-1 pt-1"
          disabled={isDefault}
          onClick={onAdd}
        >
          <div className="d-flex align-items-center">
            <i class="fas fa-plus"></i>&nbsp;Agregar  
          </div>
        </button>
      </FieldWrapper>
    </ul>
  </div>
)

const FieldWrapper = ({ children, className = "" }) => (
  <li className={"step-item " + className}>
    <div className="step-content-wrapper">
      <span className="step-icon step-icon-pseudo step-icon-soft-dark ml-2"></span>
      <div className="step-content mr-4 pt-0">
        {children}
      </div>
    </div>
  </li>
)

export default SettingsView;