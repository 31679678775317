import React from "react";
import { Modal, Loading } from "seed/helpers";
import { ColumnTypeLabels, getSelectTypes } from "components/tables/util/datatypes";
import FileViewer from "components/tables/helpers/FileViewer";
import HeaderCell from "components/tables/helpers/HeaderCell";
import Cell from "components/tables/helpers/Cell";
import Selection from "components/tables/helpers/Selection";
import { Background } from "reactflow";

const TableDatagrid = ({
  height = "auto",
  columns = [],
  rows = [],
  filteredData = [],
  filteredColumns = [],
  collections = [],
  collectionColumns = [],
  isReadOnly = false,
  isLoading = false,
  isFilePreviewShown = false,
  showOptions = true,
  tableRef,
  pageSize,
  pageNum,
  filters,
  selectedCell,
  selectedFile,
  setSelectedCell,
  setSelectedFile,
  setFormValue,
  setIsFilePreviewShown,
  setIsOptionModalShown,
  setIsFormModalShown,
  onAddColumn = () => { },
  onChangeColumn = () => { },
  onDeleteColumn = () => { },
  onAddRow = () => { },
  onDeleteRow = () => { },
  onInsertRow = () => { },
  onChangeRow = () => { },
  onDownloadZip = () => { },
  onClickCell = () => { },
  folioComponent
}) => {

  return (
    <div
      id="collection-table"
      ref={tableRef}
      className="d-flex flex-column scrollbar-narrow px-3 pt-1 mt-2 mb-1"
      style={{ height: height }}
    >

      {isLoading ? (
        <div
          id="loading-content"
          className="d-flex justify-content-center align-items-center position-absolute"
          colSpan={columns.length + 2}
        >
          <Loading />
        </div>
      ) : null}

      <Selection>
        <table className="table-hover table-sm m-0 p-0">

          <thead className="table-bordered">
            <tr>
              <th scope="col"></th>
              {
                columns.map((column, index) => (
                  <HeaderCell
                    key={column.name}
                    collections={collections}
                    column={column}
                    filteredColumns={filteredColumns}
                    isReadOnly={isReadOnly}
                    isActive={selectedCell && selectedCell.column === index && selectedCell.row === -1}
                    showOptions={showOptions}
                    onChange={(value) => onChangeColumn(index, value)}
                    onDelete={() => onDeleteColumn(index)}
                    onDownloadZip={onDownloadZip}
                    onShowOptions={() => setIsOptionModalShown(true)}
                    setSelectedCell={setSelectedCell}
                  />
                ))
              }

              {
                !isReadOnly &&
                <th
                  scope="col"
                  className="text-center text-primary add-column"
                >
                  <div class="dropdown">
                    <button
                      class="btn p-0 m-0 dropdown-toggle w-100 text-center"
                      type="button"
                      id="add-column-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="h4 p-0 d-flex justify-content-center align-items-center my-1 py-0 text-center w-100">
                        <span>+</span> {columns.length == 0 && <span className="h6 my-0 ml-1">Agregar columna</span>}
                      </div>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="add-column-dropdown">
                      <span class="dropdown-header pt-1 pb-2"><span>Nueva columna</span></span>
                      {
                        getSelectTypes().map((key) => (
                          <a
                            key={key}
                            class="dropdown-item"
                            href="#"
                            onClick={() => onAddColumn(key)}
                          >
                            {ColumnTypeLabels[key]}
                          </a>
                        ))
                      }
                    </div>
                  </div>
                </th>
              }

            </tr>
          </thead>

          <tbody className="table-bordered">
            {
              filteredData.map((row, i) => (
                <tr key={i}>
                  <td className="folio-column">
                    <div className="d-flex justify-content-center align-items-center mt-2 px-2">
                      <span
                        onClick={() => {
                          setFormValue(row);
                          setIsFormModalShown(true);
                        }}
                      >
                        {
                          folioComponent
                            ? folioComponent(row, i)
                            : <> {pageSize * (pageNum - 1) + i + 1}</>
                        }
                      </span>
                    </div>
                  </td>
                  {
                    columns.map((column, j) => (
                      <Cell
                        key={`${i}-${j}`}
                        column={column}
                        collectionColumns={collectionColumns}
                        value={(row?.data ?? {})[column.name]}
                        isReadOnly={isReadOnly}
                        isActive={selectedCell && selectedCell.row === i && selectedCell.column === j}
                        showOptions={showOptions}
                        onTypeEnter={() => {
                          if (i === rows.length - 1) onAddRow();
                          else onClickCell(row, i + 1, j);
                        }}
                        onPreviewFile={setSelectedFile}
                        onClick={() => onClickCell(row, i, j)}
                        onChange={(value) => onChangeRow(i, column.name, value)}
                        onInsert={() => onInsertRow(i)}
                        onDelete={() => onDeleteRow(i)}
                        onShowOptions={() => setIsOptionModalShown(true)}
                        setSelectedCell={setSelectedCell}
                      />
                    ))
                  }
                  {
                    !isReadOnly && <td
                      className="delete-row-button"
                      onClick={() => onDeleteRow(i)}
                    >
                      <div className="h5 d-flex justify-content-center align-items-center my-1 py-0 px-3">
                        <span>-</span>
                      </div>
                    </td>
                  }

                </tr>
              ))
            }
          </tbody>

          {
            !isReadOnly && !filters.length &&
            <tfoot className="table-bordered"
              style={{ borderTop: filteredData.length == 0 ? "1.5rem solid #F8FAFE" : "none" }}>
              <tr>
                <td
                  colSpan={columns.length + 2}
                  style={{ cursor: "pointer" }}
                  onClick={onAddRow}
                >
                  <div
                    className="h5 d-flex justify-content-center align-items-center my-0 py-2"
                    style={{
                      fontSize: "0.97em",
                      borderTop: filteredData.length == 0 ? "1px solid #d5dae0" : "none"
                    }}
                  >
                    <span className="mr-1">+</span> Agregar fila
                  </div>
                </td>
              </tr>
            </tfoot>
          }

        </table>
      </Selection>

      {
        isFilePreviewShown && <Modal
          width="300"
          height="250"
          onClose={() => setIsFilePreviewShown(false)}
          component={() => <FileViewer url={selectedFile?.url} />
          }
        />
      }

    </div>
  );

}

export default TableDatagrid;