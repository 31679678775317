import React from 'react';
import { NavLink } from "react-router-dom";
import HelpMessage from 'components/util/helpers/HelpMessage';
import { Analytics } from "components/util/analytics";

const Tab = ({ flow, route, title, caption, width, isPending, onClick = () => { } }) =>
  <HelpMessage message={caption} style={{
    backgroundColor: "#f0f3f5", border: "1px solid #e0e5ea",
    color: "#40454a", borderRadius: "10px", maxWidth: "12rem"
  }}>
    <NavLink to={route} className="dropdown-header text-center"
      style={{
        width: width, fontSize: "0.87em", borderRadius: "0.5rem",
        marginRight: "0.5rem", marginTop: "0.04rem", padding: "0.42rem 0",
        position: "relative"
      }} id="flow-tab"
      onClick={() => {
        Analytics.CreateEvent("tab_selected", { tab: title, flow: flow.id });
        sessionStorage.setItem("def_tab_" + flow.id, route);
        onClick();
      }}>
      {title}
      {
        isPending &&
        <div className="dot" style={{
          position: "absolute", top: "0.5rem", right: "0.5rem",
          width: "0.5rem", height: "0.5rem", borderRadius: "50%",
          backgroundColor: sessionStorage.getItem("def_tab_" + flow.id) == route ? "#FFF" : "#e0a000"
        }}></div>
      }
    </NavLink>
  </HelpMessage>

export { Tab }