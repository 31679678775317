import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import ExcelJS from "exceljs";
import { toast, Flip } from 'react-toastify';
import { useQuery, useDelete, useDetail, useSet } from "seed/gql";
import { usePost } from "seed/api";
import { DELETE_COLLECTION, DELETE_COLLECTION_DATA, SET_COLLECTION } from "seed/gql/queries";
import { downloadFile } from "components/util/file_util";
import { parseExportData } from "components/tables/util/datatypes";
import { formatNumber } from "components/tables/util/format";
import { Loading } from "seed/helpers";
import * as util from "components/tables/util/tutorial";
import View from "components/tables/Tables.view";

function Tables({
	flowId = null,
	initialCollectionId = null,
	showTabs = true,
	isReadOnly = false,
	styles = {
		background: "#f8fafe",
		border: "1px solid #E7EAF3",
	},
	availableCollections = [],
	refetchParentCollections = () => { }
}) {

	const userId = sessionStorage.getItem("id");
	const [activeCollectionId, setActiveCollectionId] = useState(initialCollectionId);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isImportModalOpen, setIsImportModalOpen] = useState(false);

	const [filters, setFilters] = useState(JSON.parse(sessionStorage.getItem("collection_filters")) || []);
	const [query, setQuery] = useState(sessionStorage.getItem("collection_query") || "");
	const [search, setSearch] = useState(sessionStorage.getItem("collection_search") || "");
	const [pageNum, setPageNum] = useState(parseInt(sessionStorage.getItem("collection_page")) || 1);
	const pageSize = 10;

	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	const collectionFilter = columns.filter(column => column.type === "collection")
		.map(column => `(id = ${column?.structure?.collection_id})`)
		.join(" OR ");

	const qCollectionData = useQuery(`{
		collectionColumns: collections {
			id
			name
			schema
			initials
			folios
			collectionDataes {
				id
				data
				folio
			}
		}
	}`, collectionFilter.length > 0 ? `(${collectionFilter})` : "null");

	const qCollection = useDetail(`{
		collection {
			id
			schema
			name
			initials
		}
	}`, activeCollectionId);

	const reqUser = useDetail(`{
		user {
			accountSettings
		}
	}`, userId)

	const [callGetData, reqGetData] = usePost("/collections/get_data");

	const refreshData = () => callGetData({
		collection_id: activeCollectionId,
		page_size: pageSize,
		page_number: pageNum,
		search,
		query
	});

	const [callSaveColumn, reqSaveColumn] = useSet(SET_COLLECTION, {
		onCompleted: (data) => 
			refreshData()
	});

	const [callSaveRow, reqSaveRow] = usePost("/collections/save_collection_data", {
		onCompleted: (data) => 
			setTimeout(() => 
				refreshData(), 10)
	});

	const [callSetRow, reqSetRow] = usePost("/collections/set_collection_data", {
		onError: (error) => {
			if(error.status == 400 && !toast.isActive("error-toast")) {
				toast("El tamaño del archivo excede el límite de tu licencia", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					toastId: "error-toast",
					transition: Flip,
					type: "error"
				});	
			}
		}
	});

	const [callDeleteRow, reqDeleteRow] = useDelete(DELETE_COLLECTION_DATA, {
		onCompleted: (data) => refreshData()
	});

	const [callImportRows, reqImportRows] = usePost("/collections/import_data", {
		onCompleted: (data) => refreshData()
	});

	const [callGetAllData, reqGetAllData] = usePost("/collections/get_data", {
		onCompleted: (rowsData) => {

			const sheetName = "Sheet1";
			const workbook = new ExcelJS.Workbook();
			const sheet = workbook.addWorksheet(sheetName);

			const excelColumns = [
				...columns.map((c) => c.label),
				"ID Sistema",
				"Folio Sistema"
			];

			const excelRows = rowsData.data.map((row) => [
				...columns.map((c) => parseExportData(c, row.data[c.name])),
				row.id,
				row.full_folio
			]);

			sheet.addRow(excelColumns);

			const lastRow = sheet.lastRow;
			for (let i = 0; i < columns.length + 2; i++) {
				if (i < columns.length)
					lastRow.getCell(i + 1).font = { bold: true, color: { argb: "176dc7" } };
				else
					lastRow.getCell(i + 1).font = { bold: true, color: { argb: "AABBCC" } };
			}

			excelRows.forEach((row) => sheet.addRow(row));

			workbook.xlsx.writeBuffer().then((buffer) => {
				const blob = new Blob([buffer], { type: "application/octet-stream" });
				downloadFile(URL.createObjectURL(blob), null, "export.xlsx");
			});

		}
	});

	const [callDownloadZip, reqDownloadZip] = usePost("/collections/download_zip", {
		onCompleted: (data) => {
			const a = document.createElement("a");
			a.href = data.url;
			a.download = data.filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	});

	const [callAction, reqAction] = usePost("/collections/action", {
		onCompleted: (data) => {
			qCollection.refetch();
			qCollectionData.refetch();
			refreshData();
		}
	});

	const qCollections = useQuery(`{
		collections {
			id
			name
			initials
		}
	}`, "flowId=" + flowId, {
		onCompleted: (data) => {

			const collections = data.collections.filter(c => availableCollections.length === 0 ||
				availableCollections.map(c => c.id).includes(c.id));

			if (collections.length > 0 && !activeCollectionId) {

				let defCollectionId = collections[0].id

				for (let collection of collections)
					if (collection.id == sessionStorage.getItem("def_collection_" + flowId))
						defCollectionId = collection.id

				setActiveCollectionId(defCollectionId)

			}

		}
	});

	const [callDelete, reqDelete] = useDelete(DELETE_COLLECTION, {
		onCompleted: (data) => {
			qCollections.refetch();
			refetchParentCollections();
			setActiveCollectionId(null);
		}
	});

	const [callGetRow, reqGetRow] = usePost("/collections/get_collection_data", {
		onCompleted: (data) => {
			const newRows = rows.map(row => {
				if (row.id === data.id)
					return { ...row, data: data.data }
				return row;
			});
			setRows(newRows);
		}
	});
	
	const onClickTab = (collectionId) => {
		sessionStorage.setItem("def_collection_" + flowId, collectionId)
		sessionStorage.removeItem("collection_search")
		sessionStorage.removeItem("collection_query")
		sessionStorage.removeItem("collection_filters")
		sessionStorage.removeItem("collection_page")
		sessionStorage.removeItem("collection_total_pages")
		setSearch("");
		setQuery("");
		setFilters([]);
		setActiveCollectionId(collectionId);
	}

	const onClickEdit = (collectionId) => {
		onClickTab(collectionId);
		setIsEditModalOpen(true)
	}

	const onClickDelete = (collectionId) =>
		swal({
			title: "Eliminar tabla",
			icon: "warning",
			text: "¿Estás seguro que desea eliminar esta tabla?",
			buttons: {
				confirm: {
					text: "Aceptar",
					className: "swal-button swal-button--cancel btn-success",
				},
				cancel: "Cancelar",
			},
		}).then((response) => {
			if (response) callDelete({ id: collectionId });
		})

	const onAddColumn = (newColumns, newColumn) =>
		callAction({
			type: "column",
			action: "add",
			data: {
				id: activeCollectionId,
				schema: { structure: newColumns },
				column: newColumn
			}
		});

	const onChangeColumn = (newColumns, prevKey, newKey) =>
		callAction({
			type: "column",
			action: "update",
			data: {
				id: activeCollectionId,
				schema: { structure: newColumns },
				prev_key: prevKey,
				new_key: newKey
			}
		});

	const onChangeColumnOptions = (newColumns) =>
		callSaveColumn({ id: activeCollectionId, schema: { structure: newColumns } });

	const onDeleteColumn = (newColumns, deletedColumn) =>
		callAction({ 
			type: "column", 
			action: "delete", 
			data: { 
				id: activeCollectionId, 
				schema: { structure: newColumns }, 
				column: deletedColumn
			} 
		});

	const onAddRow = (data = {}) =>
		callSaveRow({ data, collection_id: activeCollectionId });

	const onDeleteRow = (index) =>
		callDeleteRow({ id: rows[index].id });

	const onInsertRow = (auxRow) =>
		callAction({
			type: "row",
			action: "insert",
			data: {
				id: activeCollectionId,
				row: auxRow
			}
		});

	const onChangeRow = (index, newRows) => {
		setRows(newRows);
		callSetRow({ collection_data_id: newRows[index].id, data: newRows[index].data });
	}

	const onChangeSearch = (value) => {
		sessionStorage.setItem("collection_search", value)
		setSearch(value);
	}

	const onChangeFilters = (value) => {
		sessionStorage.setItem("collection_filters", JSON.stringify(value))
		setFilters(value);
	}

	const onChangeQuery = (value) => {
		sessionStorage.setItem("collection_query", value)
		setQuery(value);
	}

	const onPageChange = (page) => {
		if (page < 1) page = 1;
		if (page > totalPages) page = totalPages;
		sessionStorage.setItem("collection_page", page)
		sessionStorage.setItem("collection_total_pages", totalPages)
		setPageNum(page);
	}

	const onImport = (rows) =>
		callImportRows({
			collection_id: activeCollectionId,
			data: rows
		});

	const onExport = (extension) => {
		callGetAllData({
			collection_id: activeCollectionId,
		});
	}

	const onDownloadZip = (field_name) =>
		callDownloadZip({ collection_id: activeCollectionId, field_name });

	const onSaveCollection = (data) => {
		setIsModalOpen(false);
		setActiveCollectionId(data.id);
		qCollections.refetch();
		refetchParentCollections();
	}

	const onEditCollection = () => {
		setIsModalOpen(false);
		qCollections.refetch();
		qCollection.refetch();
		refreshData();
		refetchParentCollections();
	}

	const onClickCell = (data, i, j) =>
		callGetRow({ collection_data_id: data.id });

	const { collection } = qCollection.data || {};
	const { collections: auxCollections = [] } = qCollections.data || {};
	const { collectionColumns = [] } = qCollectionData.data || {};
	const collectionDataes = (reqGetData.data || {}).data;
	const totalPages = (reqGetData.data || {}).total_pages || 0;
	const loading = qCollection.loading || qCollections.loading || reqGetData.loading;

	const collections = auxCollections.filter(c => availableCollections.length === 0 || 
		availableCollections.map(c => c.id).includes(c.id));

	useEffect(() => {
		
		if(!availableCollections || availableCollections.length === 0) 
			return;
		
		setActiveCollectionId(null);
		qCollections.refetch();

	}, [availableCollections]);

	useEffect(() => {
		qCollections.refetch();
	}, [flowId]);

	useEffect(() => {
		setRows([]);
		qCollection.refetch();
		refreshData();
	}, [pageNum, activeCollectionId, search, query]);

	useEffect(() => {
		if (collection && collection.schema) {
			let schema = {};
			try { schema = JSON.parse(collection.schema) ?? {} }
			catch (e) { schema = (collection.schema) ?? {} }
			setColumns(schema.structure || []);
		}
	}, [collection]);

	useEffect(() => {

		if (!collection) return;

		const sortedData = Object.values(collectionDataes || []);
		sortedData.sort((a, b) => a.folio - b.folio);

		const data = sortedData.map(d => ({
			data: function () {
				try { return JSON.parse(d.data) ?? {} }
				catch (e) { return (d.data) ?? {} }
			}(),
			_folio: `${collection.initials}-${formatNumber(d.folio)}`,
			folio: d.folio,
			id: d.id,
		}));

		setRows(data);

	}, [collectionDataes, collection]);

	const collectionColumnsAux = collectionColumns.map(c => ({
		...c,
		collectionDataes: c.collectionDataes.map(d => ({
			...d,
			_folio: `${c.initials}-${formatNumber(d.folio)}`
		}))
	}));

	if (reqUser.loading || reqImportRows.loading) return <Loading />;
	const { user = {} } = reqUser.data;
	const tutorialSettings = user.accountSettings?.tutorial ?? []

	const collectionsTutorial = tutorialSettings.find((tutorial) => tutorial.name == "collections") || {};
	const isTutorialFinished = collectionsTutorial?.is_finished ?? false;
	const currentTutorialStep = collectionsTutorial?.current_step ?? 0;

	return <View
		styles={styles}
		flowId={flowId}
		showTabs={showTabs}
		columns={columns}
		rows={rows}
		pageNum={pageNum}
		pageSize={pageSize}
		totalPages={totalPages}
		collections={collections}
		collectionColumns={collectionColumnsAux}
		activeCollectionId={activeCollectionId}
		query={query}
		filters={filters}
		search={search}
		height={totalPages > 1 ? "calc(100% - 180px)" : "calc(100% - 127px)"}
		loading={loading}
		tutorialSteps={util.dataTutorialSteps}
		currentTutorialStep={currentTutorialStep}
		isTutorialShown={!isTutorialFinished}
		isModalOpen={isModalOpen}
		isEditModalOpen={isEditModalOpen}
		isImportModalOpen={isImportModalOpen}
		isReadOnly={isReadOnly}
		setColumns={setColumns}
		setIsModalOpen={setIsModalOpen}
		setIsEditModalOpen={setIsEditModalOpen}
		setIsImportModalOpen={setIsImportModalOpen}
		onClickTab={onClickTab}
		onClickDelete={onClickDelete}
		onClickEdit={onClickEdit}
		onAddColumn={onAddColumn}
		onChangeColumn={onChangeColumn}
		onChangeColumnOptions={onChangeColumnOptions}
		onDeleteColumn={onDeleteColumn}
		onAddRow={onAddRow}
		onDeleteRow={onDeleteRow}
		onInsertRow={onInsertRow}
		onChangeRow={onChangeRow}
		onImport={onImport}
		onExport={onExport}
		onDownloadZip={onDownloadZip}
		onChangeSearch={onChangeSearch}
		onPageChange={onPageChange}
		onChangeQuery={onChangeQuery}
		onChangeFilters={onChangeFilters}
		onClickCell={onClickCell}
		onSaveCollection={onSaveCollection}
		onEditCollection={onEditCollection}
	/>;
}

export default Tables;