import React from "react";
import PropTypes from "prop-types";
import { Loading } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";


const FlowsView = ({
  Flow,
  flows,
  activeFlow,
  scrollAmount,
  style,
  arrowsStyle,
  arrowContainerStyle,
  isTemplateFlowsLoading,
  onSelectFlow,
}) => (
  <div className="d-flex justify-content-center" style={style}>
    {isTemplateFlowsLoading ? <Loading /> : (
      <ScrollArrow
        scrollAmount={scrollAmount}
        leftButtonStyle={arrowsStyle}
        rightButtonStyle={arrowsStyle}
        itemsContainerStyle={arrowContainerStyle}
      >
        {flows && flows.map((flow, idx) => (
          <div
            key={flow.id}
            onClick={() => onSelectFlow(flow.id)}
            style={{ display: 'contents' }}
          >
            {Flow({
              idx: idx,
              flow: flow,
              isActive: flow.id == activeFlow
            })}
          </div>
        ))}
      </ScrollArrow>
    )}
  </div>
);

FlowsView.propTypes = {};

export default FlowsView;