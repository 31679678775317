import React from 'react';
import Table from "components/tables/Table";

const ExecModeDataTable = ({ 
  collections,
  values,
  structure,
  pageNum, 
  pageSize,
  totalPages, 
  onPageChange 
}) => {
  return <Table
    collectionColumns={collections}
    isReadOnly={true}
    useCustomPagination={true}
    showExport={false}
    showImport={false}
    showOptions={false}
    showSearch={false}
    showFilters={false}
    pageNum={pageNum}
    pageSize={pageSize}
    totalPages={totalPages}
    values={values}
    structure={structure}
    onPageChange={onPageChange}
    folioComponent={null}
  />
}

export default ExecModeDataTable;