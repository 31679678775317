import React from "react";
import { APP_URL } from "settings";
import { BrowserRouter } from "react-router-dom";
import { Modal } from "seed/helpers";
import FormSave from "components/presentation/FormSave";
import Template from "components/presentation/Template";
import Collaboration from "components/flow/forms/collaboration/Collaboration";
import "styles/css/Presentation.css";


const PresentationModeView = ({
  flow,
  reset,
  isEditModalShown,
  isTemplatesShown,
  isShareShown,
  isTutorialFinished,
  onCloseEditModal,
  onCloseTemplatesModal,
  onCloseShareModal,
  onResetPresentation
}) => (
  <BrowserRouter basename={`/p/${flow.token}`}>
    <div className="d-flex justify-content-center">
      <div id="presentation-mode" className="card mt-3 p-0">
        <div className="w-100">
          <iframe
            key={reset}
            className="w-100"
            title="Presentation mode"
            referrerpolicy="same-origin"
            id="presentation-iframe"
            src={`${APP_URL}/presentation/${flow.token}?tabs=true`}>
          </iframe>

          {!isTutorialFinished ?
            <div id="presentation-ctas-frame"
            ></div> : null}

        </div>
      </div>
    </div>

    {isEditModalShown && (
      <Modal
        width="700"
        height={window.innerHeight - 150}
        component={() => <FormSave flowId={flow.id} reset={reset} onResetPresentation={onResetPresentation} />}
        onClose={onCloseEditModal}
      />
    )}

    {isTemplatesShown && (
      <Modal
        width="700"
        height={window.innerHeight - 150}
        component={() => <Template flowId={flow.id} reset={reset} onResetPresentation={onResetPresentation} />}
        onClose={onCloseTemplatesModal}
      />
    )}

    {isShareShown && (
      <Modal
        width="650"
        height={window.innerHeight - 150}
        component={() => <Collaboration flowId={flow.id} showLink={true} />}
        onClose={onCloseShareModal}
      />
    )}

  </BrowserRouter>
)

export default PresentationModeView;